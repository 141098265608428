<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-location-field source="geom"></va-location-field>
              <va-field
                source="chemical_storage_type.chemical_storage_type"
                type="select"
                chip
                :label="
                  $t('resources.chemical_storages.fields.chemical_storage_type')
                "
                reference="chemical_storage_types"></va-field>
              <va-field source="storage_location"></va-field>
              <va-field
                source="distance_nearest_well_m"
                type="number"></va-field>
              <va-field
                source="distance_nearest_well_adequate.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.chemical_storages.fields.distance_nearest_well_adequate'
                  )
                "
                reference="common_responses"></va-field>
              <va-field
                source="distance_nearest_watercourse_m"
                type="number"></va-field>
              <va-field
                source="distance_nearest_watercourse_adequate.common_response"
                type="select"
                :label="
                  $t(
                    'resources.chemical_storages.fields.distance_nearest_watercourse_adequate'
                  )
                "
                chip
                reference="common_responses"></va-field>
              <va-field
                source="located_in_designated_area.common_response"
                type="select"
                :label="
                  $t(
                    'resources.chemical_storages.fields.located_in_designated_area'
                  )
                "
                chip
                reference="common_responses"></va-field>
              <va-field
                source="dedicated_building.common_response"
                type="select"
                chip
                :label="
                  $t('resources.chemical_storages.fields.dedicated_building')
                "
                reference="common_responses"></va-field>
              <va-field
                source="storage_location_locked.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.chemical_storages.fields.storage_location_locked'
                  )
                "
                reference="common_responses"></va-field>
              <va-field
                source="secondary_containment.common_response"
                type="select"
                chip
                :label="
                  $t('resources.chemical_storages.fields.secondary_containment')
                "
                reference="common_responses"></va-field>
              <va-field
                source="chemical_warning_sign_posted.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.chemical_storages.fields.chemical_warning_sign_posted'
                  )
                "
                reference="common_responses"></va-field>
              <va-field
                source="emergency_contact_info_posted.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.chemical_storages.fields.emergency_contact_info_posted'
                  )
                "
                reference="common_responses"></va-field>
              <va-field
                source="spill_kit_available.common_response"
                type="select"
                chip
                :label="
                  $t('resources.chemical_storages.fields.spill_kit_available')
                "
                reference="common_responses"></va-field>
              <va-field
                source="inventory_records_kept.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.chemical_storages.fields.inventory_records_kept'
                  )
                "
                reference="common_responses"></va-field>
              <va-field
                source="fire_department_notified.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.chemical_storages.fields.fire_department_notified'
                  )
                "
                reference="common_responses"></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
