<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-polygon-field source="geom"></va-polygon-field>
              <va-field
                source="manure_storage_type_entity.manure_storage_type"
                type="select"
                reference="manure_storage_types"
                chip></va-field>
              <va-field source="man_stor_loc" type="text"></va-field>
              <va-field
                source="manure_type_entity.manure_type"
                type="select"
                reference="manure_types"
                chip></va-field>
              <va-field
                source="containment_type_entity.containment_type"
                type="select"
                reference="containment_types"
                chip></va-field>
              <va-field
                source="storage_fully_contained_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="manure_storage_covered_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <v-row>
                <v-col cols="12" sm="6">
                  <va-field
                    source="manure_storage_length_ft"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="manure_storage_width_ft"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="manure_storage_diameter_ft"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="manure_storage_depth_ft"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="manure_storage_volume_m3"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="manure_storage_capacity"
                    type="number"></va-field>
                </v-col>
              </v-row>
              <va-field
                source="storage_handling_adequate_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="distance_nearest_well_m"
                type="number"></va-field>
              <va-field
                source="distance_nearest_well_adequate_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="distance_nearest_watercourse_ft"
                type="number"></va-field>
              <va-field
                source="distance_nearest_watercourse_adequate_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="runoff_fresh_water_diverted_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="runoff_fresh_water_diversion_mechanism_entity.runoff_diversion_mechanism"
                type="select"
                reference="runoff_fresh_water_diversion_mechanisms"
                chip></va-field>
              <va-field
                source="runoff_waste_water_adequately_treated_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="runoff_treatment_type_entity.runoff_treatment_type"
                type="select"
                reference="runoff_treatment_types"
                chip></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
