<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-location-field source="geom"></va-location-field>
              <va-field
                source="water_supply_type.water_supply_type"
                type="select"
                :label="$t('resources.water_supply.fields.water_supply_type')"
                chip></va-field>
              <va-field source="supplies"></va-field>
              <va-field
                source="well_status.well_status"
                type="select"
                :label="$t('resources.water_supply.fields.well_status')"
                reference="well_statuses"
                chip></va-field>
              <va-field source="well_location"></va-field>
              <va-field source="well_depth" type="number"></va-field>
              <va-field source="case_depth" type="number"></va-field>
              <va-field
                source="capped_above_ground.common_response"
                type="select"
                :label="$t('resources.water_supply.fields.capped_above_ground')"
                chip></va-field>
              <va-field
                source="well_cover_adequate.common_response"
                :label="$t('resources.water_supply.fields.well_cover_adequate')"
                type="select"
                chip></va-field>
              <va-field
                source="human_consumption.common_response"
                type="select"
                :label="$t('resources.water_supply.fields.human_consumption')"
                chip></va-field>
              <va-field
                source="irrigation.common_response"
                :label="$t('resources.water_supply.fields.irrigation')"
                type="select"
                chip></va-field>
              <va-field
                source="livestock.common_response"
                :label="$t('resources.water_supply.fields.livestock')"
                type="select"
                chip></va-field>
              <va-field
                source="frost.common_response"
                :label="$t('resources.water_supply.fields.frost')"
                type="select"
                chip></va-field>
              <va-field
                source="withdrawal_over_23k_l.common_response"
                :label="
                  $t('resources.water_supply.fields.withdrawal_over_23k_l')
                "
                type="select"
                chip></va-field>
              <va-field
                source="uses_water_meter.common_response"
                :label="$t('resources.water_supply.fields.uses_water_meter')"
                type="select"
                chip></va-field>
              <va-field
                source="withdrawal_approval.common_response"
                type="select"
                :label="$t('resources.water_supply.fields.withdrawal_approval')"
                chip></va-field>
              <va-field
                source="last_bacteria_test.time_range"
                type="select"
                :label="$t('resources.water_supply.fields.last_bacteria_test')"
                chip
                reference="time_ranges"></va-field>
              <va-field
                source="bacteria_testing_frequency"
                :label="
                  $t('resources.water_supply.fields.bacteria_testing_frequency')
                "
                type="number"></va-field>
              <va-field source="bacteria_status"></va-field>
              <va-field
                source="last_mineral_test.time_range"
                :label="$t('resources.water_supply.fields.last_mineral_test')"
                type="select"
                chip
                reference="time_ranges"></va-field>
              <va-field source="mineral_status"></va-field>
              <va-field source="treatment_system"></va-field>
              <va-field source="ns_well_id"></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
