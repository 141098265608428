<template>
  <div class="d-flex flex-wrap" :class="{ 'flex-column': column }">
    <div v-for="item in value" :key="item.id" class="ma-1">
      <v-tooltip v-if="item.phone_comment && showTooltip" top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            :small="small"
            outlined
            color="blue-grey">
            <v-icon left> {{ phoneTypeIcon(item) }} </v-icon>
            <span>{{ item.phone_number }}</span>
          </v-chip>
        </template>
        <span>{{ item.phone_comment }}</span>
      </v-tooltip>
      <v-chip v-else :small="small" outlined color="blue-grey">
        <v-icon left> {{ phoneTypeIcon(item) }} </v-icon>
        <span>{{ item.phone_number }}</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import Field from 'vuetify-admin/src/mixins/field'

const phoneTypeIcons = {
  cell: 'mdi-cellphone',
  landline: 'mdi-deskphone',
  fax: 'mdi-fax',
}

export default {
  mixins: [Field],
  props: {
    small: {
      type: Boolean,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    column: {
      type: Boolean,
    },
  },
  methods: {
    phoneTypeIcon(item) {
      const phoneType = item.phone_type_entity.phone_type
      const key = phoneType ? phoneType.toLowerCase() : 'other'
      return phoneTypeIcons[key]
    },
  },
}
</script>

<style></style>
