<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-polygon-field source="geom" :zoom="11"></va-polygon-field>
              <va-field source="efp_field_name"></va-field>
              <va-field source="efp_sample_name"></va-field>
              <va-field source="soil_sample_date"></va-field>
              <v-row>
                <v-col cols="12" sm="6">
                  <va-field source="soil_sample_depth" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="reported_area_ac" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="nsda_client_id"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="area_sqm" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="nsda_sample_num"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="nsda_order_id" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="nitrogen" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="nsda_sample_id"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_buffer_ph" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_ph" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_p205" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_om" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_ca" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_k2o" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_na" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_mg" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_al" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_s" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_cu" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_b" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_mn" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_fe" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_cec" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_zn" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="results_base_sat_ca"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="results_base_sat_k"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="results_base_sat_na"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="results_base_sat_mg"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="results_lime_req" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="results_base_sat_h"
                    type="number"></va-field>
                </v-col>
              </v-row>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
