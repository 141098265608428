<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-select-input
              source="livestock_type"
              reference="livestock_types"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-number-input
              source="livestock_num"
              append-icon="mdi-asterisk"
              :min="0"
              required></va-number-input>
            <va-select-input
              source="livestock_operation"
              reference="livestock_operations"></va-select-input>
            <va-number-input
              source="barn_time"
              :min="0"
              :max="12"></va-number-input>
            <va-select-input
              source="barn_type"
              reference="barn_types"></va-select-input>
            <va-autocomplete-input
              source="manure_storage_id"
              reference="manure_storages"></va-autocomplete-input>
            <va-number-input source="livestock_units"></va-number-input>
            <va-field
              source="manure_production"
              type="number"
              :item="item"></va-field>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        livestock_type: null,
        livestock_operation: null,
        barn_type: null,
        manure_storage_id: null,
      },
    }
  },
}
</script>
