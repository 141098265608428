import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('va-form',{attrs:{"id":_vm.id,"item":_vm.formData,"resource":_vm.resource.name,"disable-redirect":""},on:{"saved":_vm.onSaved},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"8","md":"12"}},[_c('base-material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2"},[_vm._v("Resources")])]},proxy:true}])},[_c(VCardText,[_c('div',{staticClass:"primary display-1 font-weight-medium lighten-1 pa-3 rounded elevation-3 mt-2"},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.category))])]),_c('va-group-input',{attrs:{"hide-remove":"","hide-add":"","expansion-panel":"","source":"resources","label":" ","resource":_vm.resource.name},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c(VExpansionPanelHeader,[(!props.item.report_default)?_c('va-checkbox-input',_vm._b({attrs:{"source":"check","label":props.item.full_title},nativeOn:{"click":function($event){$event.stopPropagation();}}},'va-checkbox-input',props,false)):_c('div',{staticClass:"display-1"},[_vm._v(" "+_vm._s(props.item.full_title)+" ")])],1),_c(VExpansionPanelContent,[_c('va-field',_vm._b({attrs:{"type":"text","source":"full_description","label":"Description"}},'va-field',props,false)),_c('va-field',_vm._b({attrs:{"type":"url","source":"link","label":"Link"}},'va-field',props,false))],1)]}}])})],1),_c('va-save-button')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }