<template>
  <div>
    <va-polygon-field
      :polygons="polygons"
      :zoom="8"
      height="500px"></va-polygon-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :include="['ownership']"
        :sort-by="['ownership.ownership', 'arable_area_ac']"
        :sort-desc="[true, true]"
        @update:listState="onListStateUpdate">
        <template v-slot:actions>
          <add-pid-button @saved="onSaved" />
        </template>
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import AddPidButton from '@/components/buttons/AddPidButton.vue'

export default {
  components: { AddPidButton },
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'ownership_id',
          type: 'select',
          attributes: { reference: 'ownerships' },
        },
      ],
      fields: [
        { source: 'pid', sortable: true },
        { source: 'property_name', sortable: true },
        {
          source: 'ownership.ownership',
          type: 'select',
          sortable: true,
          attributes: { chip: true },
        },
        { source: 'primary_watershed_name', sortable: true },
        {
          source: 'county',
          sortable: true,
        },
        {
          source: 'arable_area_ac',
          type: 'number',
          sortable: true,
        },
      ],
      polygons: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.polygons = listState.items.map((item) => item.geom)
      }
    },
    onSaved() {
      this.$refs.list.fetchData()
    },
  },
}
</script>
