export default [
  {
    name: 'users',
    icon: 'mdi-account-outline',
    label: 'name',
    permissions: ['admin'],
  },
  { name: 'roles', label: 'name' },
  { name: 'counties', label: 'county' },
  { name: 'coordinators', label: 'full_name' },
  { name: 'phone_types', label: 'phone_type' },
  { name: 'contact_roles', label: 'contact_role' },
  { name: 'watersheds', label: 'river' },
  {
    name: 'farms',
    icon: 'mdi-home-silo-outline',
    label: 'farm_name',
  },
  {
    name: 'summary_reports',
    icon: 'mdi-chart-box-outline',
    label: 'title',
  },
  {
    name: 'contacts',
    label: 'full_name',
  },
  { name: 'contact_phones', label: 'phone_number' },
  { name: 'comments', icon: 'mdi-comment-text-outline', label: 'comment' },
  { name: 'event_types', label: 'event_type' },
  { name: 'commodity_types', label: 'commodity_type' },
  { name: 'ownerships', label: 'ownership' },
  { name: 'question_data_entries', label: 'question_data_entry' },
  { name: 'common_responses', label: 'common_response' },
  {
    name: 'crop_types',
    label: 'class',
  },
  { name: 'tillages', label: 'tillage' },
  { name: 'amend_types', label: 'amend_type' },
  { name: 'amend_import_exports', label: 'amend_import_export' },
  { name: 'water_supply_types', label: 'water_supply_type' },
  { name: 'well_statuses', label: 'well_status' },
  { name: 'time_ranges', label: 'time_range' },
  { name: 'watercourse_types', label: 'watercourse_type' },
  {
    name: 'livestock_access_watercourses',
    label: 'livestock_access_watercourse',
  },
  {
    name: 'bacteria_testing_frequencies',
    label: 'bacteria_testing_frequency',
  },
  { name: 'pump_types', label: 'pump_type' },
  { name: 'manure_storage_types', label: 'manure_storage_type' },
  { name: 'manure_types', label: 'manure_type' },
  { name: 'containment_types', label: 'containment_type' },
  {
    name: 'livestock_types',
    label: 'class',
  },
  { name: 'livestock_operations', label: 'livestock_operation' },
  { name: 'barn_types', label: 'barn_type' },
  { name: 'feed_types', label: 'feed_type' },
  { name: 'feed_containment_types', label: 'feed_containment_type' },
  { name: 'feed_storage_types', label: 'feed_storage_type' },
  { name: 'runoff_diversion_mechanisms', label: 'runoff_diversion_mechanism' },
  { name: 'runoff_treatment_types', label: 'runoff_treatment_type' },
  { name: 'chemical_storage_types', label: 'chemical_storage_type' },
  { name: 'fuel_types', label: 'fuel_type' },
  { name: 'tank_types', label: 'tank_type' },
  { name: 'tank_materials', label: 'tank_material' },
  { name: 'risk_types', label: 'risk_type' },
  { name: 'risk_ratings', label: 'risk_rating' },
  { name: 'risks', label: 'risk_subject' },
  {
    name: 'recommendation_priorities',
    label: 'recommendation_priority',
  },
  { name: 'recommendation_resources', label: 'recommendation_id' },
  { name: 'resource_importances', label: 'resource_importance' },
  {
    name: 'stages',
    label: 'stage',
    children: [
      // Add stage related resources here
      { name: 'farm_events', label: 'event_type' },
      { name: 'commodity_farms', label: 'commodity_type_id' },
      { name: 'properties', label: 'property_name' },
      { name: 'fields', label: 'field_name' },
      { name: 'questionnaires', label: 'level1' },
      { name: 'croppings', label: 'main_crop' },
      { name: 'amendments', label: 'amend_name' },
      { name: 'soil_test_results_nsdas', label: 'efp_field_name' },
      { name: 'soil_mgmts', label: 'soil_mgmt_location' },
      { name: 'water_supply', label: 'supplies' },
      { name: 'watercourses', label: 'watercourse_name' },
      { name: 'irrigations', label: 'site_name' },
      { name: 'manure_storages', label: 'man_stor_loc' },
      { name: 'livestock_farms', label: 'livestock_num' },
      { name: 'feed_storages', label: 'feed_storage_text_id' },
      { name: 'point_source_runoffs', label: 'manure_storage_id' },
      { name: 'chemical_storages', label: 'storage_location' },
      { name: 'fuel_storages', label: 'tank_location' },
      { name: 'risk_subjects', label: 'risk_subject' },
      { name: 'recommendations', label: 'title' },
      { name: 'contents', label: 'ui_title' },
      { name: 'resources', label: 'brief_title' },
      { name: 'reports', label: 'stage_id' },
    ],
  },
]
