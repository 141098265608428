<template>
  <va-form
    :id="id"
    :item="item"
    :resource="resource"
    v-model="model"
    disable-redirect
    @saved="$emit('saved')">
    <va-text-input
      source="first_name"
      :labelKey="$t('contacts.first_name')"
      append-icon="mdi-asterisk"
      required></va-text-input>
    <va-text-input
      source="first_name_common"
      :labelKey="$t('contacts.first_name_common')"
      append-icon="mdi-asterisk"
      required></va-text-input>
    <va-text-input
      source="last_name"
      :labelKey="$t('contacts.last_name')"
      append-icon="mdi-asterisk"
      required></va-text-input>
    <va-select-input
      source="contact_role"
      reference="contact_roles"
      model="contact_role"
      :labelKey="$t('contacts.contact_role')"
      append-icon="mdi-asterisk"
      required></va-select-input>
    <va-text-input
      source="email"
      :labelKey="$t('contacts.email')"></va-text-input>
    <va-boolean-input
      source="primary_contact"
      :labelKey="$t('contacts.primary_contact')"></va-boolean-input>
    <va-text-input
      source="comment"
      :labelKey="$t('contacts.comment')"
      multiline></va-text-input>
    <va-group-input
      source="contact_phones"
      resource="contact_phones"
      label="Phones"
      v-slot="props">
      <v-row>
        <v-col cols="12" md="6">
          <!-- Label: To Be Fixed -->
          <va-select-input
            label="Phone Type"
            v-bind="props"
            source="phone_type"
            :choices="phoneTypes"
            item-text="phone_type"
            item-value="id"
            append-icon="mdi-asterisk"
            required></va-select-input>
        </v-col>
        <v-col cols="12" md="6">
          <va-text-input
            label="Phone Number"
            v-bind="props"
            source="phone_number"
            append-icon="mdi-asterisk"
            required></va-text-input>
        </v-col>
        <v-col cols="12" md="12">
          <va-text-input
            label="Phone Comment"
            v-bind="props"
            source="phone_comment"
            multiline></va-text-input>
        </v-col>
      </v-row>
    </va-group-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item', 'resource', 'farmId'],
  data() {
    return {
      model: {
        farm_id: this.farmId,
        contact_role: null,
      },
      phoneTypes: [],
    }
  },
  mounted() {
    this.model = this.item
    this.getPhoneTypes()
  },
  methods: {
    async getPhoneTypes() {
      try {
        const { data } = await this.$store.dispatch('phone_types/getList')
        this.phoneTypes = data
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
