<template>
  <va-form
    :id="id"
    :item="formData"
    :resource="resource.name"
    v-model="model"
    disable-redirect
    @saved="onSaved">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">Resources</div>
          </template>
          <v-card-text>
            <div
              class="primary display-1 font-weight-medium lighten-1 pa-3 rounded elevation-3 mt-2">
              <span class="white--text">{{ category }}</span>
            </div>
            <va-group-input
              hide-remove
              hide-add
              expansion-panel
              source="resources"
              label=" "
              :resource="resource.name"
              v-slot="props">
              <v-expansion-panel-header>
                <va-checkbox-input
                  v-if="!props.item.report_default"
                  v-bind="props"
                  source="check"
                  :label="props.item.full_title"
                  @click.native.stop>
                </va-checkbox-input>
                <div v-else class="display-1">
                  {{ props.item.full_title }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <va-field
                  v-bind="props"
                  type="text"
                  source="full_description"
                  label="Description">
                </va-field>
                <va-field v-bind="props" type="url" source="link" label="Link">
                </va-field>
              </v-expansion-panel-content>
            </va-group-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  props: ['id', 'title', 'resource'],
  watch: {
    '$route.params'(newVal, oldVal) {
      /**
       * Reload data when route params change.
       * Similar approach in vuetify-admin's ui/providor/List.vue in order to support loading nested routes.
       */
      if (!isEqual(newVal, oldVal)) {
        this.model = {
          stage_id: this.$route.params.stage_id,
        }
        this.getResources()
      }
    },
  },
  computed: {
    category() {
      return (
        this.resources &&
        this.resources[0] &&
        `${this.resources[0].level1} - ${this.resources[0].level2}`
      )
    },
  },
  data() {
    return {
      formData: {
        resources: [],
      },
      model: {
        stage_id: this.$route.params.stage_id,
      },
      resourcePriorities: [],
      resources: [],
    }
  },
  mounted() {
    this.getResources()
  },
  methods: {
    async getResources() {
      try {
        const { data } = await this.$admin.http.get(
          'api/resources/list_by_category',
          {
            params: {
              stage_id: this.$route.params.stage_id,
              resource_id: this.$route.params.resource_id,
            },
          }
        )
        this.resources = data.data
        this.parseFormData()
      } catch (error) {
        console.log(error)
      }
    },
    parseFormData() {
      this.formData = {
        resources: this.resources.map((resource, index) => {
          const {
            id,
            full_title,
            full_description,
            link,
            farm_resource,
            report_default,
          } = resource
          let farmResource = {
            number: index + 1,
            id: null,
            resource_id: id,
            check: false,
            full_title,
            full_description,
            link,
            report_default,
          }

          if (farm_resource) {
            farmResource = {
              ...farmResource,
              id: farm_resource.id,
              check: true,
              resource_id: farm_resource.resource_id,
            }
          }

          if (report_default) {
            farmResource.check = true
          }

          return farmResource
        }),
      }
    },
    onSaved() {
      this.getResources()
    },
  },
}
</script>
