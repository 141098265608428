<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-polygon-field
              v-if="item"
              source="geom"
              :zoom="11"
              :item="item"></va-polygon-field>
            <va-number-input
              source="pid"
              append-icon="mdi-asterisk"
              required></va-number-input>
            <va-text-input source="property_name"></va-text-input>
            <va-text-input source="pid_layer_version"></va-text-input>
            <va-select-input
              source="ownership_id"
              reference="ownerships"></va-select-input>
            <va-field source="county" v-if="item" :item="item"></va-field>
            <va-field
              source="primary_watershed_name"
              v-if="item"
              :item="item"></va-field>
            <va-field
              source="protected_watershed_name"
              v-if="item"
              :item="item"></va-field>
            <v-row v-if="item">
              <v-col cols="12" sm="6">
                <va-field
                  source="total_area_ac"
                  type="number"
                  :item="item"></va-field>
              </v-col>
              <v-col cols="12" sm="6">
                <va-field
                  source="arable_area_ac"
                  type="number"
                  :item="item"></va-field>
              </v-col>
              <v-col cols="12" sm="6">
                <va-field
                  source="wetlands_area_ac"
                  type="number"
                  :item="item"></va-field>
              </v-col>
              <v-col cols="12" sm="6">
                <va-field
                  source="other_area"
                  type="number"
                  :item="item"></va-field>
              </v-col>
              <v-col cols="12" sm="6">
                <va-field
                  source="watercourse_length"
                  type="number"
                  :item="item"></va-field>
              </v-col>
              <v-col cols="12" sm="6">
                <va-field source="primary_soil_type" :item="item"></va-field>
              </v-col>
              <v-col cols="12" sm="6">
                <va-field
                  source="average_slope_percentage"
                  type="number"
                  :item="item"></va-field>
              </v-col>
            </v-row>
            <va-field
              v-if="item"
              source="soil_drainage_class"
              :item="item"></va-field>
            <va-field
              v-if="item"
              source="soil_stoniness_class"
              :item="item"></va-field>
            <va-field
              v-if="item"
              source="soil_sand_percentage"
              type="number"
              :item="item"></va-field>
            <va-field
              v-if="item"
              source="soil_silt_percentage"
              type="number"
              :item="item"></va-field>
            <va-field
              v-if="item"
              source="soil_clay_percentage"
              type="number"
              :item="item"></va-field>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        ownership_id: null,
      },
    }
  },
}
</script>
