<template>
  <va-layout>
    <main-app-bar
      slot="app-bar"
      :header-menu="headerMenu"
      :profile-menu="profileMenu"
      :title="title"
      disable-create
      dark
      color="#008ec1"
      @toggle="
        $vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)
      ">
      <v-img
        slot="logo"
        class="mr-2"
        :src="require('@/assets/EFP_reverse.png')"
        max-height="50"
        max-width="100"
        contain />
      <div slot="title" class="d-flex flex-column hidden-sm-and-down">
        <span>{{ title || $admin.title }}</span>
        <span class="body-2" v-if="subTitle">{{ subTitle }}</span>
      </div>
      <va-stages-field
        v-if="isStageSubRoute"
        slot="actions"
        :item="farm"
        :stage-id="stageId"
        resource="farms"
        source="stages"></va-stages-field>
    </main-app-bar>
    <sidebar-menu
      slot="sidebar"
      :menu="sidebarMenu"
      v-model="drawer"
      :mini-variant="mini"></sidebar-menu>
    <va-aside slot="aside"></va-aside>
    <va-footer slot="footer" :menu="footerMenu"></va-footer>
  </va-layout>
</template>

<script>
import MainAppBar from './MainAppBar'
import SidebarMenu from '@/components/menus/SidebarMenu'
import nav from '../_nav'
import stageNav from '../_stageNav'
import { eventBus } from '@/main'

export default {
  name: 'AdminLayout',
  components: {
    MainAppBar,
    SidebarMenu,
  },
  computed: {
    isStageSubRoute() {
      return this.$route.path.startsWith('/stages')
    },
    sidebarMenu() {
      let mainNavItems = nav(this.$i18n, this.$admin)
      if (this.isStageSubRoute) {
        mainNavItems = mainNavItems.concat(stageNav(this.$i18n, this.$admin))
        // Place questionnaire menu after the Farm Characteristics menu
        mainNavItems.splice(6, 0, this.questionnaireMenu)
        mainNavItems.splice(
          11,
          0,
          ...[
            this.riskSubjectMenu,
            this.recommendationMenu,
            this.contentMenu,
            this.resourceMenu,
          ]
        )
      }

      return mainNavItems
    },
    farmId() {
      const stage = this.$store.state.stages.item
      return stage && stage.farm_id
    },
    title() {
      if (this.isStageSubRoute && this.farm) {
        const { farm_number, farm_name, owner_names } = this.farm
        const components = [
          farm_number ? `EFP #${farm_number}` : null,
          farm_name,
          owner_names,
        ]

        return components.filter((c) => c).join(' - ')
      }

      return null
    },
    subTitle() {
      return this.stageId ? `Stage ID #${this.stageId}` : null
    },
    stageId() {
      return this.$route.params.stage_id
    },
    questionnaireMenu() {
      if (!this.stageId || this.questionCategories.length === 0) {
        return null
      }

      return {
        icon: null,
        text: 'Questionnaires',
        children: this.questionCategories.map((category) => ({
          icon: 'mdi-view-grid',
          text: `${category.level1} (${category.answers_count}/${category.questions_count})`,
          resource: 'questionnaires',
          link: {
            name: 'questionnaires_show',
            params: {
              stage_id: this.stageId,
              questionnaire_id: category.id,
            },
          },
        })),
      }
    },
    riskSubjectMenu() {
      if (!this.stageId || this.riskSubjectGroups.length === 0) {
        return null
      }

      return {
        icon: null,
        text: 'Risks',
        children: this.riskSubjectGroups.map((riskSubjectGroup) => ({
          text: riskSubjectGroup.riskCategory,
          children: riskSubjectGroup.riskSubjects.map((riskSubject) => ({
            text: riskSubject.riskSubject,
            resource: 'risk_subjects',
            link: {
              name: 'risk_subjects_show',
              params: {
                stage_id: this.stageId,
                risk_subject_id: riskSubject.id,
              },
            },
          })),
        })),
      }
    },
    recommendationMenu() {
      if (!this.stageId || this.recommendationCategoryGroups.length === 0) {
        return null
      }

      const recommendations = [
        {
          icon: 'mdi-view-dashboard',
          text: 'Recommendations',
          resource: 'recommendations',
          exactPath: true,
          link: {
            name: 'recommendations_list',
            params: {
              stage_id: this.stageId,
            },
          },
        },
      ]

      return {
        icon: null,
        text: 'Recommendations',
        children: recommendations.concat(
          this.recommendationCategoryGroups.map((recommendationGroup) => ({
            text: recommendationGroup.level1,
            children: recommendationGroup.level2s.map((recommendation) => ({
              text: recommendation.level2,
              resource: 'recommendations',
              link: {
                name: 'recommendations_show',
                params: {
                  stage_id: this.stageId,
                  recommendation_id: recommendation.id,
                },
              },
            })),
          }))
        ),
      }
    },
    contentMenu() {
      if (!this.stageId || this.contentCategoryGroups.length === 0) {
        return null
      }

      const contents = [
        {
          icon: 'mdi-view-dashboard',
          text: 'Content',
          resource: 'contents',
          exactPath: true,
          link: {
            name: 'contents_list',
            params: {
              stage_id: this.stageId,
            },
          },
        },
      ]

      return {
        icon: null,
        text: 'Content',
        children: contents.concat(
          this.contentCategoryGroups.map((contentGroup) => ({
            text: contentGroup.level1,
            children: contentGroup.level2s.map((content) => ({
              text: content.level2,
              resource: 'contents',
              link: {
                name: 'contents_show',
                params: {
                  stage_id: this.stageId,
                  content_id: content.id,
                },
              },
            })),
          }))
        ),
      }
    },
    resourceMenu() {
      if (!this.stageId || this.resourceCategoryGroups.length === 0) {
        return null
      }

      const resources = [
        {
          icon: 'mdi-view-dashboard',
          text: 'Resources',
          resource: 'resources',
          exactPath: true,
          link: {
            name: 'resources_list',
            params: {
              stage_id: this.stageId,
            },
          },
        },
      ]

      return {
        icon: null,
        text: 'Resources',
        children: resources.concat(
          this.resourceCategoryGroups.map((resourceGroup) => ({
            text: resourceGroup.level1,
            children: resourceGroup.level2s.map((resource) => ({
              text: resource.level2,
              resource: 'resources',
              link: {
                name: 'resources_show',
                params: {
                  stage_id: this.stageId,
                  resource_id: resource.id,
                },
              },
            })),
          }))
        ),
      }
    },
  },
  data() {
    return {
      drawer: null,
      mini: false,
      headerMenu: [],
      footerMenu: [
        {
          href: 'https://nsfa-fane.ca/',
          text: 'NSFA',
        },
      ],
      profileMenu: [
        {
          icon: 'mdi-account',
          text: this.$t('menu.profile'),
          link: '/profile',
        },
      ],
      farm: null,
      questionCategories: [],
      riskSubjectGroups: [],
      recommendationCategoryGroups: [],
      contentCategoryGroups: [],
      resourceCategoryGroups: [],
    }
  },
  watch: {
    farmId: {
      handler() {
        this.fetchFarm()
      },
      immediate: true,
    },
    stageId: {
      handler() {
        this.fetchQuestionnaireMenuItems()
      },
      immediate: true,
    },
  },
  created() {
    eventBus.$on('questionnaire-changed', () => {
      this.fetchQuestionnaireMenuItems()
    })
  },
  mounted() {
    this.fetchMenuItems()
  },
  methods: {
    async fetchFarm() {
      this.farm = null

      if (!this.farmId) {
        return
      }

      const { data } = await this.$store.dispatch('farms/getOne', {
        id: this.farmId,
      })
      this.farm = data
    },
    async fetchMenuItems() {
      const promises = [
        this.$store.dispatch('risk_subjects/getList'),
        this.$admin.http.get('api/recommendations/categories'),
        this.$admin.http.get('api/contents/categories'),
        this.$admin.http.get('api/resources/categories'),
      ]

      try {
        const [
          riskSubjectResponse,
          recommendationResponse,
          contentResponse,
          resourceResponse,
        ] = await Promise.all(promises)

        const indexedRiskSubjectGroups = riskSubjectResponse.data.reduce(
          (acc, curr) => {
            acc[curr.risk_category] = acc[curr.risk_category] || {
              riskCategory: curr.risk_category,
              riskSubjects: [],
              index: Object.keys(acc).length + 1,
            }
            acc[curr.risk_category].riskSubjects.push({
              id: curr.id,
              riskSubject: curr.risk_subject,
            })
            return acc
          },
          {}
        )
        this.riskSubjectGroups = Object.values(indexedRiskSubjectGroups).sort(
          (a, b) => a.index - b.index
        )

        this.recommendationCategoryGroups = this.parseCategories(
          recommendationResponse.data.data
        )
        this.contentCategoryGroups = this.parseCategories(
          contentResponse.data.data
        )
        this.resourceCategoryGroups = this.parseCategories(
          resourceResponse.data.data
        )
      } catch (error) {
        console.log(error)
      }
    },
    async fetchQuestionnaireMenuItems() {
      if (!this.stageId) {
        return
      }

      const promises = [
        this.$admin.http.get('api/questionnaires/categories', {
          params: {
            stage_id: this.stageId,
          },
        }),
      ]

      try {
        const [questionnaireResponse] = await Promise.all(promises)

        this.questionCategories = questionnaireResponse.data
      } catch (error) {
        console.log(error)
      }
    },
    parseCategories(data) {
      const indexedCategoryGroups = data.reduce((acc, curr) => {
        acc[curr.level1] = acc[curr.level1] || {
          level1: curr.level1,
          level2s: [],
          index: Object.keys(acc).length + 1,
        }
        acc[curr.level1].level2s.push({
          id: curr.id,
          level2: curr.level2,
        })
        return acc
      }, {})
      return Object.values(indexedCategoryGroups).sort(
        (a, b) => a.index - b.index
      )
    },
  },
}
</script>
