<template>
  <div>
    <va-location-field
      :points="points"
      :zoom="11"
      multiple
      height="500px"></va-location-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        :include="[
          'pump_type',
          'irrigation_water_source_entity',
          'watercourse',
        ]"
        @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'pump_type',
          type: 'select',
          attributes: { reference: 'pump_types' },
        },
      ],
      fields: [
        { source: 'site_name', sortable: true },
        {
          source: 'irrigation_water_source_entity.well_location',
          labelKey: 'irrigation_water_source',
          attributes: { chip: true },
        },
        {
          source: 'watercourse.watercourse_name',
          labelKey: 'watercourse',
          attributes: { chip: true },
        },
        {
          source: 'pump_type.pump_type',
          type: 'select',
          labelKey: 'pump_type',
          attributes: { chip: true },
        },
      ],
      points: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.points = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
