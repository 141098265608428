<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-polygon-input source="geom" :zoom="11"></va-polygon-input>
            <va-select-input
              source="feed_storage_type"
              reference="feed_storage_types"></va-select-input>
            <va-field
              v-if="item"
              source="distance_to_well_m"
              type="number"
              :item="item"></va-field>
            <va-select-input
              source="distance_to_well_adequate"
              reference="common_responses"></va-select-input>
            <va-field
              v-if="item"
              source="distance_to_watercourse_m"
              type="number"
              :item="item"></va-field>
            <va-select-input
              source="distance_to_watercourse_adequate"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="runoff_fresh_water_diverted"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="runoff_waste_water_adequately_treated"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="runoff_treatment_type"
              reference="runoff_treatment_types"></va-select-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        geom: null,
        feed_storage_type: null,
        feed_type: null,
        feed_containment_type: null,
        storage_contained: null,
        distance_to_well_adequate: null,
        distance_to_watercourse_adequate: null,
        runoff_fresh_water_diverted: null,
        runoff_fresh_water_diversion_mech: null,
        runoff_waste_water_adequately_treated: null,
        runoff_treatment_type: null,
      },
    }
  },
}
</script>
