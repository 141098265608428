<template>
  <va-edit-layout>
    <comments-form :id="id" :title="title" :item="item"></comments-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
}
</script>
