<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input source="geom"></va-location-input>
            <va-select-input
              source="water_supply_type"
              reference="water_supply_types"
              model="water_supply_type"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-text-input
              source="supplies"
              append-icon="mdi-asterisk"
              required></va-text-input>
            <va-select-input
              source="well_status"
              reference="well_statuses"
              model="well_status"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-text-input
              source="well_location"
              append-icon="mdi-asterisk"
              required></va-text-input>
            <div class="d-flex justify-space-between">
              <va-number-input source="well_depth" class="mr-1">
              </va-number-input>
              <va-number-input
                source="case_depth"
                class="ml-1"></va-number-input>
            </div>
            <va-select-input
              source="capped_above_ground"
              reference="common_responses"
              model="capped_above_ground"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="well_cover_adequate"
              reference="common_responses"
              model="well_cover_adequate"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="human_consumption"
              reference="common_responses"
              model="human_consumption"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="irrigation"
              reference="common_responses"
              model="irrigation"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="livestock"
              reference="common_responses"
              model="livestock"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="frost"
              reference="common_responses"
              model="frost"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="withdrawal_over_23k_l"
              reference="common_responses"
              model="withdrawal_over_23k_l"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="uses_water_meter"
              reference="common_responses"
              model="uses_water_meter"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="withdrawal_approval"
              reference="common_responses"
              model="withdrawal_approval"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="last_bacteria_test"
              reference="time_ranges"
              model="last_bacteria_test"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-number-input
              source="bacteria_testing_frequency"
              model="bacteria_testing_frequency"
              :min="0"
              :max="99"></va-number-input>
            <va-text-input source="bacteria_status"></va-text-input>
            <va-select-input
              source="last_mineral_test"
              reference="time_ranges"
              model="last_mineral_test"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-text-input source="mineral_status"></va-text-input>
            <va-text-input source="treatment_system"></va-text-input>
            <va-number-input source="ns_well_id"></va-number-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        stage_id: this.$route.params.stage_id,
        geom: null,
        water_supply_type: null,
        well_status: null,
        capped_above_ground: null,
        well_cover_adequate: null,
        human_consumption: null,
        irrigation: null,
        livestock: null,
        frost: null,
        withdrawal_over_23k_l: null,
        uses_water_meter: null,
        withdrawal_approval: null,
        last_bacteria_test: null,
        bacteria_testing_frequency: null,
        last_mineral_test: null,
      },
    }
  },
}
</script>
