<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      ref="list"
      :filters="filters"
      disable-global-search
      disable-create
      disable-export>
      <template v-slot:actions>
        <va-action-button
          @click="generateNewReport"
          label="Generate New Report"
          icon="mdi-plus"
          color="success"
          text></va-action-button>
      </template>
      <va-data-table :fields="fields" disable-show disable-edit disable-clone>
        <template v-slot:[`item.actions`]="{ resource, item }">
          <download-button
            :resource="resource"
            :item="item"
            icon></download-button>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import DownloadButton from '@/components/buttons/DownloadButton'

export default {
  props: ['resource', 'title'],
  components: { DownloadButton },
  data() {
    return {
      filters: [],
      fields: [
        { source: 'name', sortable: true },
        {
          source: 'created_at',
          type: 'date',
          sortable: true,
          attributes: {
            format: 'long',
          },
        },
      ],
    }
  },
  methods: {
    async generateNewReport() {
      if (
        await this.$admin.confirm(
          'Generate new report?',
          'You are about generating a new report. This operation is irreversible!'
        )
      ) {
        try {
          const { data } = await this.$store.dispatch('reports/create', {
            data: {
              stage_id: this.$route.params.stage_id,
            },
          })
          this.$admin.toast.success(
            `New Report #${data.id} Successfully Created!`
          )
          this.$refs.list.fetchData()
        } catch (e) {
          this.$admin.toast.error(e.message)
        }
      }
    },
  },
}
</script>
