<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-location-field
                source="geom"
                :zoom="11"
                multiple></va-location-field>
              <va-field source="amend_name"></va-field>
              <va-field
                source="amend_type_entity.amend_type"
                type="select"
                reference="amend_types"
                chip></va-field>
              <va-field
                source="amend_import_export_entity.amend_import_export"
                type="select"
                reference="amend_import_exports"
                chip></va-field>
              <va-field source="amend_quant_per_yr" type="number"></va-field>
              <va-field
                source="amend_stored_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field source="amd_stor_msd_well" type="number"></va-field>
              <va-field
                source="amd_stor_msd_well_adq_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field source="amd_stor_msd_watcrs" type="number"></va-field>
              <va-field
                source="amd_stor_msd_watcrs_adq_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="amd_app_msd_well_adq_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="amd_app_msd_watcrs_adq_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
