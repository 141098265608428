import { VApp } from 'vuetify/lib/components/VApp';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VContainer,{attrs:{"fluid":"","fill-height":"","id":"login-page"}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{staticStyle:{"max-width":"350px"}},[_c('base-material-card',{attrs:{"color":"white"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c(VImg,{attrs:{"src":require("../assets/EFP_colour.png"),"max-width":"100%","alt":"EFP"}})]},proxy:true}])},[_c(VCardText,[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }