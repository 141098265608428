<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input source="geom"></va-location-input>
            <va-text-input
              source="site_name"
              append-icon="mdi-asterisk"
              required></va-text-input>
            <va-select-input
              source="irrigation_water_source"
              :choices="waterSupplyOptions"
              item-text="text"
              item-value="value"></va-select-input>
            <va-select-input
              source="watercourse_id"
              :choices="watercourseOptions"
              item-text="text"
              item-value="value"></va-select-input>
            <va-select-input
              source="pump_type"
              reference="pump_types"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="spill_control"
              reference="common_responses"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="spill_kit"
              reference="common_responses"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-select-input
              source="intake_screen"
              reference="common_responses"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-text-input source="comment"></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  computed: {
    waterSupplyOptions() {
      return this.waterSupplies.map((waterSupply) => ({
        value: waterSupply.id,
        text: `${waterSupply.water_supply_type.water_supply_type} - ${waterSupply.well_location}`,
      }))
    },
    watercourseOptions() {
      return this.watercourses.map((watercourse) => ({
        value: watercourse.id,
        text: `#${watercourse.id} ${watercourse.watercourse_name}`,
      }))
    },
  },
  data() {
    return {
      model: {
        stage_id: this.$route.params.stage_id,
        geom: null,
        irrigation_water_source: null,
        watercourse_id: null,
        pump_type: null,
        spill_control: null,
        spill_kit: null,
        intake_screen: null,
      },
      waterSupplies: [],
      watercourses: [],
    }
  },
  mounted() {
    this.getWaterSupplies()
    this.getWatercourses()
  },
  methods: {
    async getWaterSupplies() {
      try {
        const { data } = await this.$store.dispatch('water_supply/getList', {
          filter: {
            stage_id: this.$route.params.stage_id,
          },
          include: ['water_supply_type'],
        })
        this.waterSupplies = data
      } catch (error) {
        console.log(error)
      }
    },
    async getWatercourses() {
      try {
        const { data } = await this.$store.dispatch('watercourses/getList', {
          filter: {
            stage_id: this.$route.params.stage_id,
          },
          include: ['watercourse_type_entity'],
        })
        this.watercourses = data
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
