<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      disable-create
      disable-export
      :include="['resource']">
      <va-data-table :fields="fields" disable-select disable-actions>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['title', 'resource'],
  data() {
    return {
      filters: [],
      fields: [
        {
          source: 'resource.full_title',
          type: 'select',
          label: 'Title',
        },
        {
          source: 'resource.brief_description',
          type: 'select',
          label: 'Brief Description',
        },
      ],
    }
  },
}
</script>
