<template>
  <va-action-button
    @click="addWatercourses"
    label="Add Watercourses"
    icon="mdi-plus"
    color="success"
    text>
  </va-action-button>
</template>

<script>
export default {
  name: 'AddWatersourcesButton',
  components: {},
  data() {
    return {}
  },
  methods: {
    async addWatercourses() {
      if (
        await this.$admin.confirm(
          'Add Watercourses?',
          'You are about adding Watercourses based on Properties. Do you want to continue?'
        )
      ) {
        try {
          const { result } = await this.$store.dispatch('watercourses/create', {
            data: {
              stage_id: this.$route.params.stage_id,
              populate: true,
            },
          })
          this.$emit('added')
          this.$admin.toast.success(
            `${result} Watercourses Successfully Added!`
          )
        } catch (e) {
          this.$admin.toast.error(e.message)
        }
      }
    },
  },
}
</script>
