<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-polygon-field source="geom" :zoom="11"></va-polygon-field>
              <va-field source="field_id"></va-field>
              <va-field
                source="main_crop_entity.class"
                type="select"
                chip></va-field>
              <va-field source="crop_area_ac" type="number"></va-field>
              <va-field
                source="land_ownership_entity.ownership"
                type="select"
                chip></va-field>
              <va-field source="lease_to_from"></va-field>
              <va-field source="years_grown" type="number"></va-field>
              <va-field source="rotation_number" type="number"></va-field>
              <va-field source="rotation_info"></va-field>
              <va-field
                source="tillage_entity.tillage"
                type="select"
                chip></va-field>
              <va-field
                source="bare_soil_entity.common_response"
                type="select"
                chip></va-field>
              <va-field
                source="cover_crop_used_entity.common_response"
                type="select"
                chip></va-field>
              <va-field source="cover_crop_name"></va-field>
              <va-field source="cover_crop_percent" type="number"></va-field>
              <va-field
                source="mulch_used_entity.common_response"
                type="select"
                chip></va-field>
              <va-field
                source="irrigation_entity.common_response"
                type="select"
                chip></va-field>
              <va-field
                source="soil_moisture_monitoring_entity.common_response"
                type="select"
                chip></va-field>
              <va-field
                source="frost_protection_entity.common_response"
                type="select"
                chip></va-field>
              <va-field
                source="manure_used_entity.common_response"
                type="select"
                chip></va-field>
              <va-field
                source="fertilizer_used_entity.common_response"
                type="select"
                chip></va-field>
              <va-field
                source="pesticides_used_entity.common_response"
                type="select"
                chip></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
