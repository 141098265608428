<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      disable-create
      disable-export
      :include="['recommendation', 'recommendation_priority_entity']">
      <va-data-table :fields="fields" disable-select disable-actions>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['title', 'resource'],
  computed: {
    isOpenStage() {
      const openStage = this.$store.state.global.openStage
      return openStage && openStage.id === parseInt(this.$route.params.stage_id)
    },
    fields() {
      let fields = [
        {
          source: 'recommendation_order',
          label: 'Order',
          sortable: true,
        },
        {
          source: 'recommendation.title',
          label: 'Title',
        },
        {
          source: 'recommendation_text',
          type: 'text',
          label: 'Recommendation Text',
          sortable: true,
        },
        {
          source: 'recommendation_priority_entity.recommendation_priority',
          type: 'select',
          label: 'Priority',
          sortable: true,
          attributes: {
            chip: true,
          },
        },
      ]

      if (!this.isOpenStage) {
        fields = fields.concat([
          {
            source: 'environmentally_significant',
            type: 'boolean',
          },
          {
            source: 'implemented',
            type: 'boolean',
          },
          {
            source: 'funds_received',
            type: 'boolean',
          },
        ])
      }

      return fields
    },
  },
  data() {
    return {
      filters: [
        {
          source: 'recommendation_priority',
          type: 'select',
          attributes: { reference: 'recommendation_priorities' },
        },
      ],
    }
  },
}
</script>
