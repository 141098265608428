<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-input
      source="name"
      append-icon="mdi-asterisk"
      required></va-text-input>
    <va-text-input
      source="email"
      type="email"
      append-icon="mdi-asterisk"
      required></va-text-input>
    <va-select-input
      source="roles"
      reference="roles"
      append-icon="mdi-asterisk"
      required
      item-value="name"
      item-text="name"
      multiple></va-select-input>
    <va-password-input
      source="password"
      append-icon="mdi-asterisk"
      required></va-password-input>
    <va-password-input
      source="password_confirmation"
      append-icon="mdi-asterisk"
      required></va-password-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'item'],
}
</script>
