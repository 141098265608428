<template>
  <va-form
    :id="id"
    :item="item"
    :resource="resource"
    v-model="model"
    disable-redirect
    @saved="$emit('saved')">
    <va-text-input
      source="comment"
      multiline
      :labelKey="$t('comments.comment')"
      append-icon="mdi-asterisk"
      required></va-text-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item', 'resource', 'farmId'],
  data() {
    return {
      model: {
        farm_id: this.farmId,
      },
    }
  },
}
</script>
