<template>
  <va-show :item="item" :resource="resource">
    <va-field source="comment" :labelKey="$t('comments.comment')"></va-field>
    <va-field
      source="user.name"
      :labelKey="$t('comments.user.name')"
      type="select"
      reference="users"
      chip></va-field>
    <va-field
      source="created_at"
      type="date"
      :labelKey="$t('comments.created_at')"></va-field>
  </va-show>
</template>

<script>
export default {
  props: ['title', 'item', 'resource'],
}
</script>
