<template>
  <base-material-card :icon="resource.icon" title="Summary Report">
    <va-list
      ref="list"
      disable-global-search
      disable-create
      disable-pagination
      :filters="filters">
      <va-data-table
        :fields="fields"
        disable-clone
        disable-select
        disable-show
        disable-edit
        disable-delete>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['resource'],
  data() {
    return {
      fields: [
        { source: 'Category', sortable: true },
        { source: 'April', sortable: true },
        { source: 'May', sortable: true },
        { source: 'June', sortable: true },
        { source: 'July', sortable: true },
        { source: 'August', sortable: true },
        { source: 'September', sortable: true },
        { source: 'October', sortable: true },
        { source: 'November', sortable: true },
        { source: 'December', sortable: true },
        { source: 'January', sortable: true },
        { source: 'February', sortable: true },
        { source: 'March', sortable: true },
        { source: 'Total', sortable: true },
      ],
    }
  },
  computed: {
    fiscalYears() {
      return this.genFiscalYearOptions()
    },
    filters() {
      return [
        {
          source: 'year',
          type: 'select',
          alwaysOn: true,
          attributes: {
            choices: this.fiscalYears,
          },
        },
        {
          source: 'coordinator_id',
          type: 'select',
          attributes: { reference: 'coordinators' },
          alwaysOn: true,
        },
      ]
    },
  },
  methods: {
    genFiscalYearOptions() {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth()
      const currentYear = currentDate.getFullYear()
      const startYear = currentMonth >= 3 ? currentYear : currentYear - 1
      const years = []
      for (let i = 0; i < 10; i++) {
        years.push({
          value: startYear - i,
          text: `FY${startYear - i} ~ ${startYear - i + 1}`,
        })
      }

      return years
    },
  },
}
</script>
