<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="dateFormatted"
          v-bind="commonProps"
          readonly
          append-icon="mdi-calendar"
          v-on="on"
          :filled="filled"
          @click:clear="input = []"></v-text-field>
      </template>
      <v-date-picker v-model="dates" @change="updateDate" range></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import Input from 'vuetify-admin/src/mixins/input'
import Editable from 'vuetify-admin/src/mixins/editable'

/**
 * Use for date type value editing. Is composed of a readonly textfield associated to a vuetify datepicker.
 * Do not support time, use classic VaTextInput in that case.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Date on ISO format to be edited.
     * @model
     */
    value: {
      type: Array,
      default() {
        return []
      },
    },
    /**
     * Use full filled background color style.
     */
    filled: {
      type: Boolean,
      default: true,
    },
    /**
     * Name of date format to use for textfield input.
     * Must be predefined on your VueI18n plugin.
     */
    format: {
      type: String,
      default() {
        return this.$admin.options.dateFormat
      },
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
      dates: [],
    }
  },
  watch: {
    input: {
      handler(val) {
        this.dates = val
      },
      immediate: true,
    },
  },
  computed: {
    dateFormatted() {
      return this.input
        ? this.input.map((d) => this.$d(new Date(d), this.format)).join(' ~ ')
        : ''
    },
  },
  methods: {
    updateDate() {
      this.menu = false
      const dates = this.dates.map((d) => `${d} 00:00:00`)
      this.update(dates)
    },
  },
}
</script>
