<template>
  <va-create-layout>
    <watercourses-form :title="title" :item="item"></watercourses-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
