const TILE_LAYER_URL_OSM = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const TILE_LAYER_URL_IMAGERY =
  'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
const MAP_LINK_IMAGERY = '<a href="http://www.esri.com/">Esri</a>'
const WHO_LINK_IMAGERY =
  'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'

const ATTRIBUTION_OSM =
  '&copy; <a href="http://www.openstreetmap.org/">OpenStreetMap</a> contributors'
const ATTRIBUTION_IMAGERY = `&copy; ${MAP_LINK_IMAGERY}, ${WHO_LINK_IMAGERY}`

export default (map, leaflet, maxZoom) => {
  const osm = leaflet.tileLayer(TILE_LAYER_URL_OSM, {
    maxZoom,
    ATTRIBUTION_OSM,
  })

  const imagery = leaflet.tileLayer(TILE_LAYER_URL_IMAGERY, {
    maxZoom,
    ATTRIBUTION_IMAGERY,
  })

  const layerGroup = leaflet.layerGroup([osm, imagery])

  layerGroup.addTo(map)

  const baseMaps = {
    OpenStreetMap: osm,
    'Esri World Imagery': imagery,
  }

  leaflet.control.layers(baseMaps).addTo(map)
}
