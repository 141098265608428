<template>
  <va-form
    :id="id"
    :item="formData"
    :resource="resource.name"
    v-model="model"
    disable-redirect
    @saved="onSaved">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">Risks</div>
          </template>
          <v-card-text>
            <div class="display-2 mb-4">{{ riskSubject.risk_subject }}</div>
            <div
              class="primary display-1 font-weight-medium lighten-1 pa-3 rounded elevation-3">
              <span class="white--text">Previous Stages</span>
            </div>
            <v-simple-table v-if="groupedRiskHistory.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      v-for="(group, index) in groupedRiskHistory[0]"
                      :key="index">
                      {{ group.column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(group, groupIndex) in groupedRiskHistory"
                    :key="groupIndex">
                    <td v-for="(item, itemIndex) in group" :key="itemIndex">
                      <v-tooltip v-if="item.comment" top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.value || 'N/A'
                          }}</span>
                        </template>
                        <span>{{ item.comment }}</span>
                      </v-tooltip>
                      <span v-else>{{ item.value || 'N/A' }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-else class="text-center my-8">No data</div>
            <div
              class="primary display-1 font-weight-medium lighten-1 pa-3 rounded elevation-3 mt-4">
              <span class="white--text">Current Stage</span>
            </div>
            <va-group-input
              hide-remove
              hide-add
              source="risks"
              label=" "
              :resource="resource.name"
              v-slot="props">
              <va-select-input
                :label="props.item.title"
                v-bind="props"
                source="risk_rating"
                :choices="riskRatings"
                item-text="risk_rating"
                item-value="id"></va-select-input>
              <va-text-input
                :label="`${props.item.title} Comment`"
                v-bind="props"
                source="comment"
                multiline>
              </va-text-input>
            </va-group-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  props: ['id', 'title', 'resource'],
  watch: {
    '$route.params'(newVal, oldVal) {
      /**
       * Reload data when route params change.
       * Similar approach in vuetify-admin's ui/providor/List.vue in order to support loading nested routes.
       */
      if (!isEqual(newVal, oldVal)) {
        this.model = {
          stage_id: this.$route.params.stage_id,
        }
        this.fetchRiskSubject()
        this.getRisks()
        this.getRiskHistory()
      }
    },
    riskTypes() {
      this.parseFormData()
      this.parseGroupedRiskHistory()
    },
    risks() {
      this.parseFormData()
    },
    riskHistory() {
      this.parseGroupedRiskHistory()
    },
  },
  computed: {},
  data() {
    return {
      formData: {
        risks: [],
      },
      model: {
        stage_id: this.$route.params.stage_id,
      },
      riskSubject: {},
      riskTypes: [],
      riskRatings: [],
      risks: [],
      riskHistory: [],
      groupedRiskHistory: [],
    }
  },
  mounted() {
    this.fetchRiskSubject()
    this.getRiskTypes()
    this.getRiskRatings()
    this.getRisks()
    this.getRiskHistory()
  },
  methods: {
    async fetchRiskSubject() {
      try {
        const { data } = await this.$store.dispatch('risk_subjects/getOne', {
          id: this.$route.params.risk_subject_id,
        })
        this.riskSubject = data
      } catch (error) {
        console.log(error)
      }
    },
    async getRiskTypes() {
      try {
        const { data } = await this.$store.dispatch('risk_types/getList')
        this.riskTypes = data
      } catch (error) {
        console.log(error)
      }
    },
    async getRiskRatings() {
      try {
        const { data } = await this.$store.dispatch('risk_ratings/getList')
        this.riskRatings = data
      } catch (error) {
        console.log(error)
      }
    },
    async getRisks() {
      try {
        const { data } = await this.$admin.http.get('api/risk_subjects/risks', {
          params: {
            stage_id: this.$route.params.stage_id,
            risk_subject_id: this.$route.params.risk_subject_id,
          },
        })
        this.risks = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async getRiskHistory() {
      try {
        const { data } = await this.$admin.http.get(
          'api/risk_subjects/risk_history',
          {
            params: {
              stage_id: this.$route.params.stage_id,
              risk_subject_id: this.$route.params.risk_subject_id,
            },
          }
        )
        this.riskHistory = data.data
      } catch (error) {
        console.log(error)
      }
    },
    parseFormData() {
      if (this.riskTypes.length === 0) {
        this.formData = {
          risks: [],
        }
        return
      }

      let indexRisks = this.risks.reduce((acc, item) => {
        const { id, risk_type, risk_rating, comment } = item
        acc[risk_type] = {
          id,
          risk_type,
          risk_rating,
          comment,
        }

        return acc
      }, {})

      const risks = this.riskTypes.reduce((acc, item) => {
        const { id, risk_type } = item
        const risk = indexRisks[id] || {
          id: null,
          risk_type: id,
          risk_rating: null,
          comment: null,
        }

        risk.title = risk_type

        acc.push(risk)
        return acc
      }, [])

      this.formData = {
        risks,
      }
    },
    parseGroupedRiskHistory() {
      if (this.riskTypes.length === 0 || this.riskHistory.length === 0) {
        this.groupedRiskHistory = []
        return
      }

      let grouped = this.riskHistory.reduce((acc, item) => {
        const { stage } = item.stage
        acc[stage] = acc[stage] || {
          stage,
          risks: {},
        }

        acc[stage].risks[item.risk_type] = {
          riskRating:
            (item.risk_rating_entity && item.risk_rating_entity.risk_rating) ||
            null,
          comment: item.comment,
        }

        return acc
      }, {})

      grouped = Object.values(grouped)
        .sort((a, b) => a.stage - b.stage)
        .map((stage) => {
          const risks = this.riskTypes.map((riskType) => {
            const risk = {
              column: riskType.risk_type,
              value: null,
              comment: null,
            }

            const riskExists = stage.risks[riskType.id]

            if (riskExists) {
              risk.value = riskExists.riskRating
              risk.comment = riskExists.comment
            }

            return risk
          })

          risks.unshift({
            column: 'Stage',
            value: stage.stage,
            comment: null,
          })

          return risks
        })

      this.groupedRiskHistory = grouped
    },
    onSaved() {
      this.getRisks()
      this.getRiskHistory()
    },
  },
}
</script>
