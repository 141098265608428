<template>
  <div>
    <va-show-layout :actions="['list', 'edit']">
      <va-show :item="item">
        <v-row justify="center">
          <v-col lg="8" md="12">
            <base-material-card>
              <template v-slot:heading>
                <div class="display-2">
                  {{ title }}
                </div>
              </template>
              <v-card-text>
                <va-location-field source="geom"></va-location-field>
                <v-row>
                  <v-col cols="12" sm="3">
                    <va-field source="farm_number"></va-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <va-field
                      source="coordinator.full_name"
                      type="select"
                      reference="coordinators"
                      :labelKey="$t('coordinator.coordinator')"
                      chip></va-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <va-field source="active" type="boolean"></va-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <va-field source="bn"></va-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <va-field source="registered" type="boolean"></va-field>
                  </v-col>
                </v-row>
                <va-field source="farm_name"></va-field>
                <va-field source="owner_names"></va-field>
                <va-field source="alt_name"></va-field>
                <va-field source="address1"></va-field>
                <v-row>
                  <v-col cols="12" sm="6">
                    <va-field source="community_civic"></va-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <va-field
                      source="county.county"
                      type="select"
                      reference="counties"
                      chip></va-field>
                  </v-col>
                </v-row>
                <va-field source="address2"></va-field>
                <v-row>
                  <v-col cols="12" sm="6">
                    <va-field source="community_mailing"></va-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <va-field
                      source="formatted_postal_code"
                      :labelKey="$t('postal_code')"></va-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <va-field
                      source="formatted_phone"
                      :labelKey="$t('phone')"></va-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <va-field
                      source="formatted_fax"
                      :labelKey="$t('fax')"></va-field>
                  </v-col>
                </v-row>
                <va-field source="email"></va-field>
                <v-row>
                  <v-col cols="12" sm="6">
                    <va-field source="watershed"></va-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <va-field source="designated_watershed_id"></va-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>
      </va-show>
    </va-show-layout>
    <contacts-list
      v-if="item"
      class="mt-12"
      :resource="$admin.getResource('contacts')"
      :farm-id="item.id"></contacts-list>
    <comments-list
      v-if="item"
      class="mt-12"
      :resource="$admin.getResource('comments')"
      :farm-id="item.id"></comments-list>
  </div>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
