<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-polygon-field source="geom" :zoom="11"></va-polygon-field>
              <va-field source="pid"></va-field>
              <va-field source="property_name"></va-field>
              <va-field source="pid_layer_version"></va-field>
              <va-field
                source="ownership.ownership"
                type="select"
                reference="ownerships"
                chip></va-field>
              <va-field source="county"></va-field>
              <va-field source="primary_watershed_name"></va-field>
              <va-field source="protected_watershed_name"></va-field>
              <v-row>
                <v-col cols="12" sm="6">
                  <va-field source="total_area_ac" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="arable_area_ac" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="wetlands_area_ac" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="other_area" type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="watercourse_length"
                    type="number"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field source="primary_soil_type"></va-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <va-field
                    source="average_slope_percentage"
                    type="number"></va-field>
                </v-col>
              </v-row>
              <va-field source="soil_drainage_class"></va-field>
              <va-field source="soil_stoniness_class"></va-field>
              <va-field source="soil_sand_percentage" type="number"></va-field>
              <va-field source="soil_silt_percentage" type="number"></va-field>
              <va-field source="soil_clay_percentage" type="number"></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
