<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input source="geom"></va-location-input>
            <va-text-input source="soil_mgmt_location"></va-text-input>
            <va-text-input source="issue"></va-text-input>
            <va-select-input
              source="crop_type"
              reference="crop_types"
              model="crop_type"></va-select-input>
            <va-text-input source="current_management"></va-text-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        geom: null,
        crop_type: null,
      },
    }
  },
}
</script>
