<template>
  <va-show :item="item">
    <va-field source="name"></va-field>
    <va-field source="email" type="email"></va-field>
    <va-field source="roles" type="array"></va-field>
  </va-show>
</template>

<script>
export default {
  props: ['item'],
}
</script>
