<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-select-input
              source="commodity_type_id"
              reference="commodity_types"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-number-input
              source="commodity_rank"
              append-icon="mdi-asterisk"
              required></va-number-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        stage_id: this.$route.params.stage_id,
        commodity_type_id: null,
      },
    }
  },
}
</script>
