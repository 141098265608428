import Vue from 'vue'
import VuetifyAdmin from 'vuetify-admin'

import 'vuetify-admin/src/loader'

import {
  laravelDataProvider,
  sanctumAuthProvider,
} from 'vuetify-admin/src/providers'
import { en } from 'vuetify-admin/src/locales'

import router from '@/router'
import routes from '@/router/admin'
import store from '@/store'
import i18n from '@/i18n'
import resources from '@/resources'
import axios from 'axios'
import trimEnd from 'lodash/trimEnd'

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin)

/**
 * Axios instance
 */
// const baseURL =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:8569'
//     : process.env.VUE_APP_API_URL

const baseURL = window.location.origin.includes('localhost')
  ? 'http://localhost:8569'
  : window.location.origin

const http = axios.create({
  baseURL,
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: 'NSFA',
  routes,
  locales: {
    en,
  },
  translations: ['en'],
  dataProvider: laravelDataProvider(http),
  authProvider: sanctumAuthProvider(http),
  resources,
  http,
  options: {
    dateFormat: 'short',
    tinyMCE: {
      language: navigator.language.replace('-', '_'),
      imageUploadUrl: '/api/upload',
      fileBrowserUrl: `${trimEnd(baseURL, '/')}/elfinder/tinymce5`,
    },
    map: {
      coords: [44.76964933446794, -64.66428068610871],
      zoom: 10,
      maxZoom: 18,
    },
  },
})
