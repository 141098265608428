<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters">
      <va-data-table :fields="fields" disable-clone> </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [],
      fields: [
        { source: 'efp_field_name', sortable: true },
        { source: 'efp_sample_name', sortable: true },
        { source: 'soil_sample_date', sortable: true },
        { source: 'reported_area_ac', type: 'number', sortable: true },
        { source: 'results_ph', type: 'number', sortable: true },
        { source: 'results_om', type: 'number', sortable: true },
      ],
    }
  },
}
</script>
