<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-location-field source="geom"></va-location-field>
              <va-field
                source="fuel_type.fuel_type"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.fuel_type')"
                reference="fuel_types"></va-field>
              <va-field
                source="tank_type.tank_type"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.tank_type')"
                reference="tank_types"></va-field>
              <va-field
                source="tank_material.tank_material"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.tank_material')"
                reference="tank_materials"></va-field>
              <va-field source="tank_volume_l"></va-field>
              <va-field source="year_of_manufacture"></va-field>
              <va-field
                source="csa_ulc_approved.common_response"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.csa_ulc_approved')"
                reference="common_responses"></va-field>
              <va-field source="tank_location"></va-field>
              <va-field
                source="dedicated_building.common_response"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.dedicated_building')"
                reference="common_responses"></va-field>
              <va-field
                source="concrete_pad.common_response"
                type="select"
                chip
                reference="common_responses"></va-field>
              <va-field
                source="tank_protection_adequate.common_response"
                type="select"
                chip
                :label="
                  $t('resources.fuel_storages.fields.tank_protection_adequate')
                "
                reference="common_responses"></va-field>
              <va-field
                source="secondary_containment.common_response"
                type="select"
                chip
                :label="
                  $t('resources.fuel_storages.fields.secondary_containment')
                "
                reference="common_responses"></va-field>
              <va-field
                source="electric_pump.common_response"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.electric_pump')"
                reference="common_responses"></va-field>
              <va-field
                source="auto_shutoff.common_response"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.auto_shutoff')"
                reference="common_responses"></va-field>
              <va-field
                source="lock_on_pump.common_response"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.lock_on_pump')"
                reference="common_responses"></va-field>
              <va-field
                source="emergency_numbers_posted.common_response"
                type="select"
                chip
                :label="
                  $t('resources.fuel_storages.fields.emergency_numbers_posted')
                "
                reference="common_responses"></va-field>
              <va-field
                source="distance_nearest_well_m"
                type="number"></va-field>
              <va-field
                source="distance_nearest_well_adequate.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.fuel_storages.fields.distance_nearest_well_adequate'
                  )
                "
                reference="common_responses"></va-field>
              <va-field
                source="distance_nearest_watercourse_m"
                type="number"></va-field>
              <va-field
                source="distance_nearest_watercourse_adequate.common_response"
                type="select"
                chip
                :label="
                  $t(
                    'resources.fuel_storages.fields.distance_nearest_watercourse_adequate'
                  )
                "
                reference="common_responses"></va-field>
              <va-field
                source="furnace_oil_guard.common_response"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.furnace_oil_guard')"
                reference="common_responses"></va-field>
              <va-field
                source="furnace_oil_outlet_top.common_response"
                type="select"
                chip
                :label="
                  $t('resources.fuel_storages.fields.furnace_oil_outlet_top')
                "
                reference="common_responses"></va-field>
              <va-field
                source="mobile_inspected_annually.common_response"
                type="select"
                chip
                :label="
                  $t('resources.fuel_storages.fields.mobile_inspected_annually')
                "
                reference="common_responses"></va-field>
              <va-field
                source="mobile_pressure_tested.common_response"
                type="select"
                chip
                :label="
                  $t('resources.fuel_storages.fields.mobile_pressure_tested')
                "
                reference="common_responses"></va-field>
              <va-field
                source="mobile_spill_kit.common_response"
                type="select"
                chip
                :label="$t('resources.fuel_storages.fields.mobile_spill_kit')"
                reference="common_responses"></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
