<template>
  <div>
    <va-line-field
      :polylines="polylines"
      :zoom="11"
      multiple
      height="500px"></va-line-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :include="[
          'watercourse_type_entity',
          'livestock_access_entity',
          'irrigation_entity',
        ]"
        @update:listState="onListStateUpdate">
        <template v-slot:actions>
          <add-watercourses-button @added="onAdded" />
        </template>
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import AddWatercoursesButton from '@/components/buttons/AddWatercourseButton.vue'

export default {
  components: { AddWatercoursesButton },
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'watercourse_type',
          type: 'select',
          attributes: { reference: 'watercourse_types' },
        },
        {
          source: 'livestock_access',
          type: 'select',
          attributes: { reference: 'livestock_access_watercourses' },
        },
        {
          source: 'watercourse_crossing',
          type: 'boolean',
        },
        {
          source: 'irrigation',
          type: 'select',
          attributes: { reference: 'common_responses' },
        },
      ],
      fields: [
        {
          source: 'watercourse_type_entity.watercourse_type',
          type: 'select',
          labelKey: 'watercourse_type',
          attributes: { chip: true },
        },
        { source: 'watercourse_name', sortable: true },
        { source: 'watercourse_location', sortable: true },
        {
          source: 'livestock_access_entity.livestock_access_watercourse',
          labelKey: 'livestock_access',
          type: 'select',
          attributes: {
            chip: true,
          },
        },
        { source: 'watercourse_crossing', type: 'boolean' },
        {
          source: 'irrigation_entity.common_response',
          labelKey: 'irrigation',
          type: 'select',
          attributes: { chip: true },
        },
      ],
      polylines: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.polylines = listState.items.map((item) => item.geom)
      }
    },
    onAdded() {
      this.$refs.list.fetchData()
    },
  },
}
</script>
