<template>
  <va-create-layout>
    <soil-mgmts-form :title="title" :item="item"></soil-mgmts-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
