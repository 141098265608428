<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input source="geom"></va-location-input>
            <va-select-input
              source="fuel_type"
              reference="fuel_types"></va-select-input>
            <va-select-input
              source="tank_type"
              reference="tank_types"></va-select-input>
            <va-select-input
              source="tank_material"
              reference="tank_materials"></va-select-input>
            <div class="d-flex justify-between">
              <va-number-input
                source="tank_volume_l"
                class="mr-2"></va-number-input>
              <va-number-input
                source="year_of_manufacture"
                class="ml-2"></va-number-input>
            </div>
            <va-select-input
              source="csa_ulc_approved"
              reference="common_responses"></va-select-input>
            <va-text-input source="tank_location"></va-text-input>
            <va-select-input
              source="dedicated_building"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="concrete_pad"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="tank_protection_adequate"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="secondary_containment"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="electric_pump"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="auto_shutoff"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="lock_on_pump"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="emergency_numbers_posted"
              reference="common_responses"></va-select-input>
            <va-field
              v-if="item"
              source="distance_nearest_well_m"
              type="number"
              :item="item"></va-field>
            <va-select-input
              source="distance_nearest_well_adequate"
              reference="common_responses"></va-select-input>
            <va-field
              v-if="item"
              source="distance_nearest_watercourse_m"
              type="number"
              :item="item"></va-field>
            <va-select-input
              source="distance_nearest_watercourse_adequate"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="furnace_oil_guard"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="furnace_oil_outlet_top"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="mobile_inspected_annually"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="mobile_pressure_tested"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="mobile_spill_kit"
              reference="common_responses"></va-select-input>
            <va-text-input source="comment"></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        stage_id: this.$route.params.stage_id,
        geom: null,
        fuel_type: null,
        tank_type: null,
        tank_material: null,
        csa_ulc_approved: null,
        dedicated_building: null,
        concrete_pad: null,
        tank_protection_adequate: null,
        secondary_containment: null,
        electric_pump: null,
        auto_shutoff: null,
        lock_on_pump: null,
        emergency_numbers_posted: null,
        distance_nearest_well_adequate: null,
        distance_nearest_watercourse_adequate: null,
        furnace_oil_guard: null,
        furnace_oil_outlet_top: null,
        mobile_inspected_annually: null,
        mobile_pressure_tested: null,
        mobile_spill_kit: null,
      },
    }
  },
}
</script>
