<template>
  <div>
    <va-action-button
      @click="onClickAdd"
      label="Add PIDs"
      icon="mdi-plus"
      color="success"
      text>
    </va-action-button>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="text-left">
          <span class="display-1">Add PIDs</span>
        </v-card-title>
        <v-card-text>
          <va-form disable-redirect v-model="model" @saved="onSaved">
            <va-text-input
              source="pids"
              multiline
              hint="A comma delimited list of PIDs (e.g. 85020378,85108272,85159481)"
              required></va-text-input>
            <va-save-button></va-save-button>
          </va-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddPidButton',
  components: {},
  data() {
    return {
      dialog: false,
      model: {
        stage_id: this.$route.params.stage_id,
        pids: null,
      },
    }
  },
  methods: {
    onClickAdd() {
      this.dialog = true
    },
    onSaved() {
      this.dialog = false
      this.$emit('saved')
    },
  },
}
</script>
