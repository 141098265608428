<template>
  <div id="leaflet-map" :style="style"></div>
</template>

<script>
import genMap from '@/utils/gen-map'
import L from 'leaflet'
import Field from 'vuetify-admin/src/mixins/field'

export default {
  mixins: [Field],
  props: {
    zoom: {
      type: Number,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    polygons: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    style() {
      return this.height ? { height: this.height } : null
    },
    polygonCoords() {
      if (this.polygons.length > 0) {
        return this.polygons.map((polygon) => polygon.coordinates)
      }

      if (!this.value) {
        return [[this.$admin.options.map.coords]]
      }

      if (this.value.type.toUpperCase() === 'MULTIPOLYGON') {
        return this.value.coordinates.map((coordinates) => coordinates[0])
      }

      if (Array.isArray(this.value)) {
        return this.value.map((polygon) => polygon.coordinates)
      }

      return this.value.coordinates
    },
    center() {
      return this.polygonCoords[0][0]
    },
  },
  watch: {
    polygons() {
      this.reloadPolygons()
    },
  },
  data() {
    return {
      map: null,
      featureGroup: null,
    }
  },
  mounted() {
    this.initializeMap()
    this.reloadPolygons()
  },
  methods: {
    initializeMap() {
      this.map = L.map('leaflet-map').setView(
        this.center,
        this.zoom || this.$admin.options.map.zoom
      )

      genMap(this.map, L, this.$admin.options.map.maxZoom)
    },
    reloadPolygons() {
      if (this.featureGroup) {
        this.featureGroup.removeFrom(this.map)
      }

      const polygons = this.polygonCoords.map((coords) => L.polygon(coords))
      this.featureGroup = new L.featureGroup(polygons)
      this.featureGroup.addTo(this.map)
      this.map.fitBounds(this.featureGroup.getBounds())
    },
  },
}
</script>

<style></style>
