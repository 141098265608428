<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input
              source="geom"
              :zoom="11"
              required></va-location-input>
            <va-autocomplete-input
              source="manure_storage_id"
              reference="manure_storages"
              model="manure_storage_id"></va-autocomplete-input>
            <va-autocomplete-input
              source="feed_storage_id"
              reference="feed_storages"
              model="feed_storage_id"
              :minChars="1"></va-autocomplete-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        geom: null,
        manure_storage_id: null,
        feed_storage_id: null,
      },
    }
  },
}
</script>
