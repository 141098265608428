<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      :include="[
        'amend_type_entity',
        'amend_import_export_entity',
        'amend_stored_entity',
      ]">
      <va-data-table :fields="fields" disable-clone> </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [],
      fields: [
        { source: 'amend_name', sortable: true },
        {
          source: 'amend_type_entity.amend_type',
          type: 'select',
          attributes: { chip: true },
        },
        {
          source: 'amend_import_export_entity.amend_import_export',
          type: 'select',
          attributes: { chip: true },
        },
        { source: 'amend_quant_per_yr', type: 'number', sortable: true },
        {
          source: 'amend_stored_entity.common_response',
          type: 'select',
          attributes: { chip: true },
        },
        'comment',
      ],
    }
  },
}
</script>
