<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      ref="list"
      :filters="filters"
      :include="[
        'county',
        'stages',
        'latestStage.primaryCommodityFarm.commodityTypeEntity',
        'latestStage.secondaryCommodityFarm.commodityTypeEntity',
        'primaryContact',
      ]">
      <va-data-table
        :fields="fields"
        disable-clone
        disable-select
        disable-delete>
        <template v-slot:[`item.actions`]="{ resource, item }">
          <stage-button
            :resource="resource"
            :item="item"
            icon
            @created="onCreated"></stage-button>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import StageButton from '@/components/buttons/StageButton'

export default {
  props: ['resource', 'title'],
  components: { StageButton },
  data() {
    return {
      filters: [
        { source: 'active', type: 'boolean' },
        {
          source: 'coordinator_id',
          type: 'select',
          attributes: { reference: 'coordinators' },
        },
        {
          source: 'county_id',
          type: 'select',
          attributes: { reference: 'counties' },
        },
        {
          source: 'watershed',
          type: 'select',
          attributes: { reference: 'watersheds' },
        },
        {
          source: 'primary_commodity_id',
          type: 'select',
          attributes: { reference: 'commodity_types' },
        },
        {
          source: 'commodity_id',
          type: 'select',
          attributes: { reference: 'commodity_types' },
        },
        { source: 'stage_initiation_date_range', type: 'daterangepicker' },
        { source: 'interview_date_range', type: 'daterangepicker' },
        { source: 'farm_visit_date_range', type: 'daterangepicker' },
        { source: 'final_report_date_range', type: 'daterangepicker' },
        { source: 'appendix_a_and_b_date_range', type: 'daterangepicker' },
      ],
      fields: [
        { source: 'active', type: 'boolean', sortable: true },
        { source: 'farm_number', sortable: true },
        { source: 'bn', sortable: true },
        { source: 'farm_name', sortable: true },
        {
          source: 'primary_contact.full_name',
          labelKey: 'primary_contact',
          type: 'select',
          attributes: { chip: true },
        },
        {
          source: 'county.county',
          type: 'select',
          attributes: { reference: 'counties', chip: true },
        },
        {
          source:
            'latest_stage.primary_commodity_farm.commodity_type_entity.commodity_type',
          labelKey: 'primary_commodity',
          type: 'select',
          attributes: { chip: true },
        },
        {
          source:
            'latest_stage.secondary_commodity_farm.commodity_type_entity.commodity_type',
          labelKey: 'secondary_commodity',
          type: 'select',
          attributes: { chip: true },
        },
        {
          source: 'stages',
          labelKey: 'stage.name',
          type: 'stages',
          attributes: {
            routeName: 'farm_events_list',
            currentOnly: true,
          },
        },
      ],
    }
  },
  methods: {
    onCreated() {
      this.$refs.list.fetchData()
    },
  },
}
</script>
