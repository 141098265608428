<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <comments-show
        v-if="show"
        :resource="resource.name"
        :item="item"></comments-show>
      <comments-form
        v-else
        :resource="resource.name"
        :id="id"
        :item="item"
        :farm-id="farmId"
        @saved="onSaved"></comments-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="resource.pluralName">
      <va-list
        ref="list"
        :resource="resource.name"
        disable-query-string
        disable-create-redirect
        @action="onAction"
        :filter="{
          farm: farmId,
        }"
        :filters="filters"
        :include="['user']">
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          disable-clone
          @item-action="onAction"></va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title', 'farmId'],
  data() {
    return {
      filters: [],
      fields: [
        { source: 'created_at', type: 'date', sortable: true },
        {
          source: 'user.name',
          type: 'select',
          attributes: { reference: 'users', chip: true },
        },
        {
          source: 'comment',
          attributes: { multiline: true },
          sortable: true,
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    }
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title
      this.id = id
      this.show = action === 'show'
      this.item = item
    },
    onSaved() {
      this.asideTitle = null
      this.$refs.list.fetchData()
    },
  },
}
</script>
