import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('va-show-layout',{attrs:{"actions":['list', 'edit', 'delete']}},[_c('va-show',{attrs:{"item":_vm.item}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"8","md":"12"}},[_c('base-material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true}])},[_c(VCardText,[_c('va-location-field',{attrs:{"source":"geom"}}),_c('va-field',{attrs:{"source":"chemical_storage_type.chemical_storage_type","type":"select","chip":"","label":_vm.$t('resources.chemical_storages.fields.chemical_storage_type'),"reference":"chemical_storage_types"}}),_c('va-field',{attrs:{"source":"storage_location"}}),_c('va-field',{attrs:{"source":"distance_nearest_well_m","type":"number"}}),_c('va-field',{attrs:{"source":"distance_nearest_well_adequate.common_response","type":"select","chip":"","label":_vm.$t(
                  'resources.chemical_storages.fields.distance_nearest_well_adequate'
                ),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"distance_nearest_watercourse_m","type":"number"}}),_c('va-field',{attrs:{"source":"distance_nearest_watercourse_adequate.common_response","type":"select","label":_vm.$t(
                  'resources.chemical_storages.fields.distance_nearest_watercourse_adequate'
                ),"chip":"","reference":"common_responses"}}),_c('va-field',{attrs:{"source":"located_in_designated_area.common_response","type":"select","label":_vm.$t(
                  'resources.chemical_storages.fields.located_in_designated_area'
                ),"chip":"","reference":"common_responses"}}),_c('va-field',{attrs:{"source":"dedicated_building.common_response","type":"select","chip":"","label":_vm.$t('resources.chemical_storages.fields.dedicated_building'),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"storage_location_locked.common_response","type":"select","chip":"","label":_vm.$t(
                  'resources.chemical_storages.fields.storage_location_locked'
                ),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"secondary_containment.common_response","type":"select","chip":"","label":_vm.$t('resources.chemical_storages.fields.secondary_containment'),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"chemical_warning_sign_posted.common_response","type":"select","chip":"","label":_vm.$t(
                  'resources.chemical_storages.fields.chemical_warning_sign_posted'
                ),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"emergency_contact_info_posted.common_response","type":"select","chip":"","label":_vm.$t(
                  'resources.chemical_storages.fields.emergency_contact_info_posted'
                ),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"spill_kit_available.common_response","type":"select","chip":"","label":_vm.$t('resources.chemical_storages.fields.spill_kit_available'),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"inventory_records_kept.common_response","type":"select","chip":"","label":_vm.$t(
                  'resources.chemical_storages.fields.inventory_records_kept'
                ),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"fire_department_notified.common_response","type":"select","chip":"","label":_vm.$t(
                  'resources.chemical_storages.fields.fire_department_notified'
                ),"reference":"common_responses"}}),_c('va-field',{attrs:{"source":"comment"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }