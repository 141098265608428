<template>
  <va-form
    :id="id"
    :item="formData"
    :resource="resource.name"
    v-model="model"
    disable-redirect
    @saved="onSaved">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">Content</div>
          </template>
          <v-card-text>
            <div
              class="primary display-1 font-weight-medium lighten-1 pa-3 rounded elevation-3 mt-2">
              <span class="white--text">{{ category }}</span>
            </div>
            <va-group-input
              hide-remove
              hide-add
              expansion-panel
              source="contents"
              label=" "
              :resource="resource.name"
              v-slot="props">
              <v-expansion-panel-header>
                <va-checkbox-input
                  v-if="!props.item.report_default"
                  v-bind="props"
                  source="check"
                  :label="props.item.ui_title"
                  @click.native.stop>
                </va-checkbox-input>
                <div v-else class="display-1">
                  {{ props.item.ui_title }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <va-number-input
                  v-bind="props"
                  source="content_order"
                  label="Order">
                </va-number-input>
                <va-text-input v-bind="props" source="title" label="Title">
                </va-text-input>
                <va-text-input
                  v-bind="props"
                  source="content"
                  label="Content"
                  multiline>
                </va-text-input>
                <div class="d-flex space-between">
                  <va-field
                    class="mr-4"
                    v-bind="props"
                    type="boolean"
                    source="efp_guide"
                    label="EFP Guide">
                  </va-field>
                  <va-field
                    class="mr-4"
                    v-bind="props"
                    type="boolean"
                    source="efp_report"
                    label="EFP Report">
                  </va-field>
                  <va-field
                    class="mr-4"
                    v-bind="props"
                    source="guide_page"
                    label="Guide Page">
                  </va-field>
                </div>
                <va-field
                  type="text"
                  v-bind="props"
                  source="comment"></va-field>
              </v-expansion-panel-content>
            </va-group-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  props: ['id', 'title', 'resource'],
  watch: {
    '$route.params'(newVal, oldVal) {
      /**
       * Reload data when route params change.
       * Similar approach in vuetify-admin's ui/providor/List.vue in order to support loading nested routes.
       */
      if (!isEqual(newVal, oldVal)) {
        this.model = {
          stage_id: this.$route.params.stage_id,
        }
        this.getContents()
      }
    },
  },
  computed: {
    category() {
      return (
        this.contents &&
        this.contents[0] &&
        `${this.contents[0].level1} - ${this.contents[0].level2}`
      )
    },
  },
  data() {
    return {
      formData: {
        contents: [],
      },
      model: {
        stage_id: this.$route.params.stage_id,
      },
      contents: [],
    }
  },
  mounted() {
    this.getContents()
  },
  methods: {
    async getContents() {
      try {
        const { data } = await this.$admin.http.get(
          'api/contents/list_by_category',
          {
            params: {
              stage_id: this.$route.params.stage_id,
              content_id: this.$route.params.content_id,
            },
          }
        )
        this.contents = data.data
        this.parseFormData()
      } catch (error) {
        console.log(error)
      }
    },
    parseFormData() {
      this.formData = {
        contents: this.contents.map((item, index) => {
          const {
            id,
            content_order,
            report_title,
            ui_title,
            content,
            efp_guide,
            guide_page,
            efp_report,
            report_default,
            comment,
            farm_content,
          } = item
          let farmContent = {
            number: index + 1,
            check: false,
            id: null,
            content_id: id,
            content_order,
            title: report_title,
            ui_title,
            content,
            efp_guide,
            guide_page,
            efp_report,
            comment,
            report_default,
          }

          if (farm_content) {
            farmContent = {
              ...farmContent,
              check: true,
              id: farm_content.id,
              content_id: farm_content.content_id,
              content_order: farm_content.content_order,
              title: farm_content.title,
              content: farm_content.content,
            }

            if (report_default) {
              farmContent.check = true
            }
          }

          return farmContent
        }),
      }
    },
    onSaved() {
      this.getContents()
    },
  },
}
</script>
