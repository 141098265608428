<template>
  <v-checkbox
    :false-value="false"
    :true-value="true"
    :input-value="input"
    v-bind="commonProps"
    hide-details
    @change="changeState"></v-checkbox>
</template>

<script>
import Input from 'vuetify-admin/src/mixins/input'
import Editable from 'vuetify-admin/src/mixins/editable'

/**
 * Edit value as boolean. Rendered as a switch.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Boolean to be edited.
     * @model
     */
    value: Boolean,
  },
  methods: {
    changeState(value) {
      this.change(value)
      this.update(value)
    },
  },
}
</script>
