<template>
  <va-action-button
    :item="item"
    @click="createStage"
    :hide-label="icon"
    label="Create Stage"
    icon="mdi-plus-circle-outline"
    color="success"
    text></va-action-button>
</template>

<script>
export default {
  name: 'StageButton',
  props: {
    item: Object,
    icon: Boolean,
  },
  methods: {
    async createStage() {
      if (
        await this.$admin.confirm(
          'Create new stage?',
          'You are about creating a new stage. This operation is irreversible!'
        )
      ) {
        try {
          const { data } = await this.$admin.http.post(`/api/stages`, {
            farm_id: this.item.id,
          })
          this.$emit('created')
          this.$admin.toast.success(
            `New Stage #${data.data.stage} Successfully Created!`
          )
        } catch (e) {
          this.$admin.toast.error(e.message)
        }
      }
    },
  },
}
</script>
