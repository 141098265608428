<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-polygon-input
              source="geom"
              :zoom="11"
              multiple
              :required="false"></va-polygon-input>
            <va-number-input source="field_id"></va-number-input>
            <va-select-input
              source="main_crop"
              reference="crop_types"
              model="main_crop"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-number-input
              source="crop_area_ac"
              :min="0"
              append-icon="mdi-asterisk"
              required></va-number-input>
            <va-select-input
              source="land_ownership"
              reference="ownerships"
              model="land_ownership"></va-select-input>
            <va-text-input source="lease_to_from"></va-text-input>
            <va-number-input source="years_grown"></va-number-input>
            <va-number-input source="rotation_number"></va-number-input>
            <va-text-input source="rotation_info" multiline></va-text-input>
            <va-select-input
              source="tillage"
              reference="tillages"
              model="tillage"></va-select-input>
            <va-select-input
              source="bare_soil"
              reference="common_responses"
              model="bare_soil"></va-select-input>
            <va-select-input
              source="cover_crop_used"
              reference="common_responses"
              model="cover_crop_used"></va-select-input>
            <va-text-input source="cover_crop_name"></va-text-input>
            <va-number-input source="cover_crop_percent"></va-number-input>
            <va-select-input
              source="mulch_used"
              reference="common_responses"
              model="mulch_used"></va-select-input>
            <va-select-input
              source="irrigation"
              reference="common_responses"
              model="irrigation"></va-select-input>
            <va-select-input
              source="soil_moisture_monitoring"
              reference="common_responses"
              model="soil_moisture_monitoring"></va-select-input>
            <va-select-input
              source="frost_protection"
              reference="common_responses"
              model="frost_protection"></va-select-input>
            <va-select-input
              source="manure_used"
              reference="common_responses"
              model="manure_used"></va-select-input>
            <va-select-input
              source="fertilizer_used"
              reference="common_responses"
              model="fertilizer_used"></va-select-input>
            <va-select-input
              source="pesticides_used"
              reference="common_responses"
              model="pesticides_used"></va-select-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        geom: null,
        main_crop: null,
        land_ownership: null,
        tillage: null,
        bare_soil: null,
        cover_crop_used: null,
        mulch_used: null,
        irrigation: null,
        soil_moisture_monitoring: null,
        frost_protection: null,
        manure_used: null,
        fertilizer_used: null,
        pesticides_used: null,
      },
    }
  },
}
</script>
