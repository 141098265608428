<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-location-field source="geom"></va-location-field>
              <va-field
                source="manure_storage.man_stor_loc"
                type="select"
                reference="manure_storages"
                chip></va-field>
              <va-field
                source="feed_storage.feed_storage_type_entity.feed_storage_type"
                type="select"
                chip></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
