<template>
  <div v-show="value && !!value.length">
    <v-menu v-if="!currentOnly" offset-y rounded="0">
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="mt-2 mb-2"
          v-bind="attrs"
          v-on="on"
          :color="currentStageColor"
          small>
          <v-icon left> mdi-menu-down </v-icon>
          <h2>Stage {{ currentStage && currentStage.stage }}</h2>
          <v-icon right small :title="stageIconTitle(currentStage)">{{
            stageIcon(currentStage)
          }}</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item v-for="item in value" :key="item.id" link :to="to(item)">
          <v-list-item-content>
            <v-list-item-title
              v-text="`Stage ${item.stage}`"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="stageIcon(item)" :title="stageIconTitle(item)">
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-else
      class="mt-2 mb-2"
      color="success"
      small
      :to="to(currentStage)">
      <h2>Stage {{ currentStage && currentStage.stage }}</h2>
      <v-icon right small :title="stageIconTitle(currentStage)">{{
        stageIcon(currentStage)
      }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Field from 'vuetify-admin/src/mixins/field'

const SPECIAL_CASE_RESOURCES = [
  'questionnaires',
  'risk_subjects',
  'recommendations',
  'contents',
  'resources',
]

export default {
  mixins: [Field],
  props: {
    routeName: {
      type: String,
      default: null,
    },
    stageId: {
      type: String,
      default: null,
    },
    currentOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    openStage() {
      if (this.value && this.value.length > 0) {
        return this.value.find((stage) => !stage.locking_field)
      }

      return null
    },
    currentStage() {
      if (this.value && this.value.length > 0) {
        return this.stageId
          ? this.value.find((stage) => stage.id === parseInt(this.stageId))
          : this.value[0]
      }

      return null
    },
    currentRouteName() {
      /**
       * Special case for questionnaires/risk_subjects
       */
      if (SPECIAL_CASE_RESOURCES.includes(this.currentResourceName)) {
        return this.$route.name
      }
      return this.routeName || `${this.$route.meta.resource}_list`
    },
    currentResourceName() {
      return this.$route.meta.resource
    },
    currentStageColor() {
      return this.currentStage &&
        this.openStage &&
        this.currentStage.id === this.openStage.id
        ? 'success'
        : 'error'
    },
  },
  watch: {
    currentStage: {
      handler(val) {
        if (val && !!val.locking_field && !this.currentOnly) {
          this.playFoghorn()
        }
      },
      immediate: true,
    },
    openStage: {
      handler(val) {
        this.$store.commit('global/setOpenStage', val)
      },
      immediate: true,
    },
  },
  methods: {
    stageIcon(stage) {
      return stage && stage.locking_field !== null
        ? 'mdi-check-circle-outline'
        : 'mdi-progress-clock'
    },
    stageIconTitle(stage) {
      return stage && stage.locking_field !== null ? 'Completed' : 'In Progress'
    },
    to(item) {
      const to = {
        name: this.currentRouteName,
        params: { stage_id: `${item.id}` },
      }

      return to
    },
    playFoghorn() {
      const audio = new Audio(require('@/assets/fog-horn-sound.mp3'))
      audio.play()
    },
  },
}
</script>

<style></style>
