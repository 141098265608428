/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  { divider: true },
  // Add menu items for stage related resources here
  {
    text: 'Events',
    icon: null,
    expanded: false,
    children: [admin.getResourceLink('farm_events')],
  },
  {
    text: 'Farm Characteristics',
    icon: null,
    expanded: false,
    children: [
      admin.getResourceLink('commodity_farms'),
      admin.getResourceLink('properties'),
      admin.getResourceLink('fields'),
    ],
  },
  {
    text: 'Water',
    icon: null,
    expanded: false,
    children: [
      admin.getResourceLink('water_supply'),
      admin.getResourceLink('watercourses'),
      admin.getResourceLink('irrigations'),
    ],
  },
  {
    text: 'Storage',
    icon: null,
    expanded: false,
    children: [
      admin.getResourceLink('chemical_storages'),
      admin.getResourceLink('fuel_storages'),
    ],
  },
  {
    text: 'Soil & Cropping',
    icon: null,
    expanded: false,
    children: [
      admin.getResourceLink('croppings'),
      admin.getResourceLink('amendments'),
      admin.getResourceLink('soil_test_results_nsdas'),
      admin.getResourceLink('soil_mgmts'),
    ],
  },
  {
    text: 'Livestock',
    icon: null,
    expanded: false,
    children: [
      admin.getResourceLink('livestock_farms'),
      admin.getResourceLink('manure_storages'),
      admin.getResourceLink('feed_storages'),
      admin.getResourceLink('point_source_runoffs'),
    ],
  },
  {
    text: 'Reports',
    icon: null,
    expanded: false,
    children: [admin.getResourceLink('reports')],
  },
]
