<template>
  <v-app>
    <v-container fluid fill-height id="login-page">
      <v-layout align-center justify-center>
        <v-flex style="max-width: 350px">
          <base-material-card color="white">
            <template v-slot:heading>
              <v-img
                src="../assets/EFP_colour.png"
                max-width="100%"
                alt="EFP" />
            </template>
            <v-card-text>
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style scoped>
#login-page {
  background-color: #719b49;
}
</style>
