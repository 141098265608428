<template>
  <va-edit-layout :actions="['list', 'show', 'delete']">
    <fuel-storages-form
      :id="id"
      :title="title"
      :item="item"></fuel-storages-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
}
</script>
