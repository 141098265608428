<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      disable-global-search
      :filters="filters"
      :include="['commodity_type_entity']"
      :sort-by="['commodity_rank']"
      :sort-desc="[false]">
      <va-data-table :fields="fields" disable-clone> </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'commodity_type_id',
          type: 'select',
          attributes: { reference: 'commodity_types' },
        },
      ],
      fields: [
        {
          source: 'commodity_type_entity.commodity_type',
          type: 'select',
          attributes: { reference: 'commodity_types', chip: true },
        },
        { source: 'commodity_rank', type: 'number', sortable: true },
      ],
    }
  },
}
</script>
