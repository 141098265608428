<template>
  <div>
    <va-polygon-field
      :polygons="polygons"
      :zoom="8"
      height="500px"></va-polygon-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list :filters="filters" @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [],
      fields: [
        { source: 'field_name', sortable: true },
        { source: 'comment', sortable: true },
        { source: 'created_at', sortable: true, type: 'date' },
      ],
      polygons: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.polygons = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
