<template>
  <va-create-layout>
    <point-source-runoffs-form :title="title" :item="item">
    </point-source-runoffs-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
