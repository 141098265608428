<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" :include="['crop_type_entity']">
      <va-data-table :fields="fields" disable-clone> </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [],
      fields: [
        { source: 'soil_mgmt_location', sortable: true },
        { source: 'issue', sortable: true },
        {
          source: 'crop_type_entity.class',
          type: 'select',
          attributes: { reference: 'crop_types', chip: true },
        },
        { source: 'current_management', sortable: true },
        'comment',
      ],
    }
  },
}
</script>
