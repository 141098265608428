<template>
  <div>
    <va-edit-layout :actions="['list', 'show']">
      <farms-form :id="id" :title="title" :item="item"></farms-form>
    </va-edit-layout>
    <contacts-list
      v-if="item"
      class="mt-12"
      :resource="$admin.getResource('contacts')"
      :farm-id="item.id"></contacts-list>
    <comments-list
      v-if="item"
      class="mt-12"
      :resource="$admin.getResource('comments')"
      :farm-id="item.id"></comments-list>
  </div>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {}
  },
}
</script>
