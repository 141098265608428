import Vue from 'vue'
import AdminLayout from '@/layouts/Admin'
import Profile from '@/views/Profile'
import Error from '@/views/Error'
import i18n from '@/i18n'

/**
 * Error component
 */
Vue.component('Error', Error)

export default {
  path: '',
  name: 'home',
  redirect: '/dashboard',
  component: AdminLayout,
  meta: {
    title: i18n.t('routes.home'),
  },
  children: [
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: '/farms',
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        title: i18n.t('routes.profile'),
      },
    },
    {
      path: '*',
      component: Error,
      meta: {
        title: i18n.t('routes.not_found'),
      },
    },
  ],
}
