<template>
  <div>
    <va-location-field
      :points="points"
      :zoom="11"
      multiple
      height="500px"></va-location-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        :include="['water_supply_type', 'well_status']"
        @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'water_supply_type',
          type: 'select',
          attributes: { reference: 'water_supply_types' },
        },
        {
          source: 'well_status',
          type: 'select',
          attributes: { reference: 'well_statuses' },
        },
      ],
      fields: [
        {
          source: 'water_supply_type.water_supply_type',
          type: 'select',
          label: this.$t('resources.water_supply.fields.water_supply_type'),
          attributes: { reference: 'water_supply_types', chip: true },
        },
        { source: 'supplies', sortable: true },
        {
          source: 'well_location',
          sortable: true,
        },
        {
          source: 'well_status.well_status',
          type: 'select',
          label: this.$t('resources.water_supply.fields.well_status'),
          attributes: { reference: 'well_statuses', chip: true },
        },
      ],
      points: [],
    }
  },
  methods: {
    onCreated() {
      this.$refs.list.fetchData()
    },
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.points = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
