<template>
  <!-- Stage show page will be displaying the actually stage related resource pages -->
  <router-view></router-view>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
