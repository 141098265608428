<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input source="geom"></va-location-input>
            <va-select-input
              source="chemical_storage_type"
              reference="chemical_storage_types"></va-select-input>
            <va-text-input source="storage_location"></va-text-input>
            <va-field
              v-if="item"
              type="number"
              source="distance_nearest_well_m"
              :item="item"></va-field>
            <va-select-input
              source="distance_nearest_well_adequate"
              reference="common_responses"></va-select-input>
            <va-field
              v-if="item"
              type="number"
              source="distance_nearest_watercourse_m"
              :item="item"></va-field>
            <va-select-input
              source="distance_nearest_watercourse_adequate"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="located_in_designated_area"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="dedicated_building"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="storage_location_locked"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="secondary_containment"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="chemical_warning_sign_posted"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="emergency_contact_info_posted"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="spill_kit_available"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="inventory_records_kept"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="fire_department_notified"
              reference="common_responses"></va-select-input>
            <va-text-input source="comment"></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        stage_id: this.$route.params.stage_id,
        geom: null,
        chemical_storage_type: null,
        distance_nearest_well_adequate: null,
        distance_nearest_watercourse_adequate: null,
        located_in_designated_area: null,
        dedicated_building: null,
        storage_location_locked: null,
        secondary_containment: null,
        chemical_warning_sign_posted: null,
        emergency_contact_info_posted: null,
        spill_kit_available: null,
        inventory_records_kept: null,
        fire_department_notified: null,
      },
    }
  },
}
</script>
