<template>
  <div>
    <va-location-field
      :points="points"
      :zoom="11"
      multiple
      height="500px"></va-location-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        :include="['fuel_type', 'tank_type', 'concrete_pad']"
        @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'fuel_type',
          type: 'select',
          attributes: { reference: 'fuel_types' },
        },
        {
          source: 'tank_type',
          type: 'select',
          attributes: { reference: 'tank_types' },
        },
        {
          source: 'concrete_pad',
          type: 'select',
          attributes: { reference: 'common_responses' },
        },
      ],
      fields: [
        {
          source: 'fuel_type.fuel_type',
          type: 'select',
          labelKey: 'fuel_type',
          attributes: { chip: true },
        },
        {
          source: 'tank_type.tank_type',
          type: 'select',
          labelKey: 'tank_type',
          attributes: { chip: true },
        },
        { source: 'year_of_manufacture', sortable: true },
        { source: 'tank_volume_l', sortable: true },
        {
          source: 'concrete_pad.common_response',
          type: 'select',
          labelKey: 'concrete_pad',
          attributes: { chip: true },
        },
      ],
      points: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.points = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
