<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-location-field source="geom"></va-location-field>
              <va-field source="site_name"></va-field>
              <va-field
                source="irrigation_water_source_entity.well_location"
                type="select"
                chip
                labelKey="irrigation_water_source"></va-field>
              <va-field
                source="watercourse.watercourse_name"
                type="select"
                chip
                labelKey="watercourse"></va-field>
              <va-field
                source="pump_type.pump_type"
                type="select"
                chip
                labelKey="pump_type"
                reference="pump_types"></va-field>
              <va-field
                source="spill_control.common_response"
                type="select"
                chip
                labelKey="spill_control"
                reference="common_responses"></va-field>
              <va-field
                source="spill_kit.common_response"
                type="select"
                chip
                labelKey="spill_kit"
                reference="common_responses"></va-field>
              <va-field
                source="intake_screen.common_response"
                type="select"
                chip
                labelKey="intake_screen"
                reference="common_responses"></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
