<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input
              source="geom"
              :zoom="11"
              multiple
              :required="false"></va-location-input>
            <va-text-input source="amend_name"></va-text-input>
            <va-select-input
              source="amend_type"
              reference="amend_types"
              model="amend_type"></va-select-input>
            <va-select-input
              source="amend_import_export"
              reference="amend_import_exports"
              model="amend_import_export"></va-select-input>
            <va-number-input
              source="amend_quant_per_yr"
              :min="0"
              :step="1"></va-number-input>
            <va-select-input
              source="amend_stored"
              reference="common_responses"
              model="amend_stored"></va-select-input>
            <va-number-input source="amd_stor_msd_well"></va-number-input>
            <va-select-input
              source="amd_stor_msd_well_adq"
              reference="common_responses"
              model="amd_stor_msd_well_adq"></va-select-input>
            <va-number-input source="amd_stor_msd_watcrs"></va-number-input>
            <va-select-input
              source="amd_stor_msd_watcrs_adq"
              reference="common_responses"
              model="amd_stor_msd_watcrs_adq"></va-select-input>
            <va-select-input
              source="amd_app_msd_well_adq"
              reference="common_responses"
              model="amd_app_msd_well_adq"></va-select-input>
            <va-select-input
              source="amd_app_msd_watcrs_adq"
              reference="common_responses"
              model="amd_app_msd_watcrs_adq"></va-select-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        stage_id: this.$route.params.stage_id,
        geom: null,
        amend_type: null,
        amend_import_export: null,
        amend_stored: null,
        amd_stor_msd_well_adq: null,
        amd_stor_msd_watcrs_adq: null,
        amd_app_msd_well_adq: null,
        amd_app_msd_watcrs_adq: null,
      },
    }
  },
}
</script>
