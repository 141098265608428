<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-polygon-field source="geom" :zoom="11"></va-polygon-field>
              <va-field source="field_name"></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
