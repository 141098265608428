var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('va-list',{ref:"list",attrs:{"filters":_vm.filters,"include":[
      'county',
      'stages',
      'latestStage.primaryCommodityFarm.commodityTypeEntity',
      'latestStage.secondaryCommodityFarm.commodityTypeEntity',
      'primaryContact',
    ]}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-clone":"","disable-select":"","disable-delete":""},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ resource, item }){return [_c('stage-button',{attrs:{"resource":resource,"item":item,"icon":""},on:{"created":_vm.onCreated}})]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }