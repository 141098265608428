<template>
  <div class="mb-8">
    <va-input v-bind="$props" class="va-group-input" v-if="input">
      <v-expansion-panels v-if="expansionPanel" inset>
        <v-expansion-panel v-for="(item, i) in input" :key="item.groupItemId">
          <slot
            :resource="resource"
            :item="item"
            :parent-source="source"
            :index="i"></slot>
          <v-btn
            v-if="!hideRemove"
            outlined
            color="red"
            @click.stop="remove(i)">
            <v-icon small>mdi-minus-circle-outline</v-icon>
            <span class="ml-2">{{ $t('va.actions.remove') }}</span>
          </v-btn>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else>
        <div v-for="(item, i) in input" :key="item.groupItemId">
          <slot
            :resource="resource"
            :item="item"
            :parent-source="source"
            :index="i"></slot>
          <v-btn
            v-if="!hideRemove"
            outlined
            color="red"
            @click.stop="remove(i)">
            <v-icon small>mdi-minus-circle-outline</v-icon>
            <span class="ml-2">{{ $t('va.actions.remove') }}</span>
          </v-btn>
        </div>
      </div>
    </va-input>
    <v-btn
      v-if="!hideAdd"
      slot="footer"
      block
      outlined
      color="green"
      @click.stop="add">
      <v-icon small>mdi-plus-circle-outline</v-icon>
      <span class="ml-2">{{ $t('va.actions.add') }}</span>
    </v-btn>
  </div>
</template>

<script>
import Input from 'vuetify-admin/src/mixins/input'

export default {
  mixins: [Input],
  props: {
    hideRemove: {
      type: Boolean,
      default: false,
    },
    hideAdd: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    expansionPanel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getItem(value) {
      /**
       * Generate fake id for drag tracking
       */
      return (value || []).map((v, i) => {
        return { ...v, groupItemId: i }
      })
    },
    add() {
      let maxGroupItemId = Math.max(...this.input.map((o) => o.groupItemId))
      maxGroupItemId = maxGroupItemId >= 0 ? maxGroupItemId : 0
      this.input.push({
        groupItemId: maxGroupItemId + 1,
      })
      this.update(this.input)
    },
    remove(index) {
      this.input.splice(index, 1)
      this.update(this.input)
    },
  },
}
</script>

<style></style>
