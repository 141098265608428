<template>
  <va-create-layout>
    <soil-test-results-nsdas-form
      :title="title"
      :item="item"></soil-test-results-nsdas-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
