<template>
  <va-create-layout>
    <chemical-storages-form
      :title="title"
      :item="item"></chemical-storages-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
