<template>
  <!--
    Toggle sidebar.
    @event input
  -->
  <v-navigation-drawer
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    :dark="dark"
    :color="color"
    :mini-variant="miniVariant"
    :value="value"
    @input="(v) => $emit('input', v)">
    <template v-slot:img="props">
      <!-- @slot Image background. -->
      <slot name="img" v-bind="props"></slot>
    </template>
    <v-list dense>
      <template v-for="(item, index) in menu.filter((l) => l)">
        <v-subheader v-if="item.heading && !miniVariant" :key="index">
          {{ item.heading }}
        </v-subheader>
        <v-divider v-else-if="item.divider" :key="index"></v-divider>
        <v-list-group
          v-else-if="item.children && item.children.length"
          :key="index"
          :value="routeMatch(item)"
          :prepend-icon="item.icon"
          append-icon="mdi-chevron-up">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(child, childIndex) in item.children">
            <v-list-group
              v-if="child.children && child.children.length"
              :key="childIndex"
              :value="routeMatch(child)"
              no-action
              sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(subChild, i) in child.children"
                :key="i"
                :to="subChild.link"
                link>
                <v-list-item-title>{{ subChild.text }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              :key="childIndex"
              link
              :to="child.link"
              :exact-path="!!child.exactPath">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item v-else-if="item.text" :key="index" link :to="item.link">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
/**
 * Default customizable admin VNavigationDrawer with full hierarchical menu and minimize variant.
 */
export default {
  props: {
    /**
     * Main place for side menu, support hierarchical structure, MDI icons, heading and dividers.
     */
    menu: {
      type: Array,
      default: () => [],
    },
    /**
     * Minimize the sidebar and show only icons.
     */
    miniVariant: Boolean,
    /**
     * Main color of VNavigationDrawer.
     */
    color: {
      type: String,
      default: 'white',
    },
    /**
     * Apply dark theme variant for VNavigationDrawer
     */
    dark: Boolean,
    /**
     * Control visibility
     */
    value: null,
  },
  methods: {
    routeMatch(item) {
      if (!item.children) {
        return false
      }

      return (
        item.children.filter((child) => {
          const childMatchesRoute = this.routeMatch(child)
          if (childMatchesRoute) {
            return true
          }

          if (!child.resource) {
            return false
          }

          const routeMatch = child.resource === this.$route.meta.resource
          if (!routeMatch) {
            return false
          }

          const itemIdKey = this.$route.meta.itemIdKey
          if (child.link && child.link.params && child.link.params[itemIdKey]) {
            return (
              child.link.params[itemIdKey] + '' ===
              this.$route.params[itemIdKey] + ''
            )
          } else {
            return true
          }
        }).length > 0
      )
    },
  },
}
</script>
