<template>
  <div>
    <div id="leaflet-map"></div>
    <v-text-field
      v-if="!multiple"
      v-bind="commonProps"
      filled
      v-model="coordsString"
      hint="Lat,Lng (e.g. 44.77,-64.66)"
      :append-icon="required ? 'mdi-asterisk' : null"
      :rules="[coordsRules.coords]"
      :required="required"
      @input="onInput">
    </v-text-field>
  </div>
</template>

<script>
import L from 'leaflet'
import Input from 'vuetify-admin/src/mixins/input'
import genMap from '@/utils/gen-map'

export default {
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    coords() {
      if (this.input) {
        return this.multiple ? this.input.coordinates : [this.input.coordinates]
      } else {
        return this.multiple ? [] : [this.$admin.options.map.coords]
      }
    },
    center() {
      return this.coords.length > 0
        ? this.coords[0]
        : this.$admin.options.map.coords
    },
  },
  watch: {
    coords: {
      handler(val) {
        this.coordsString = val ? val.join(',') : null
        this.reloadMarkers()
      },
      immediate: true,
    },
  },
  data() {
    return {
      map: null,
      editableLayers: null,
      marker: null,
      coordsString: null,
      coordsRules: {
        coords: (value) => {
          return this.validCoords(value) || 'Invalid Coordinates'
        },
      },
    }
  },
  mounted() {
    this.initializeMap()
  },
  methods: {
    initializeMap() {
      this.map = L.map('leaflet-map').setView(
        this.center,
        this.zoom || this.$admin.options.map.zoom
      )

      genMap(this.map, L, this.$admin.options.map.maxZoom)

      if (this.multiple) {
        this.editableLayers = new L.FeatureGroup()
        this.map.addLayer(this.editableLayers)

        const drawPluginOptions = {
          position: 'topright',
          draw: {
            polyline: false,
            polygon: false,
            rectangle: false,
            circle: false,
            marker: true,
            circlemarker: false,
          },
          edit: {
            featureGroup: this.editableLayers,
            remove: true,
          },
        }

        const drawControl = new L.Control.Draw(drawPluginOptions)
        this.map.addControl(drawControl)

        this.map
          .on('draw:created', this.onCreated)
          .on('draw:edited', this.onEdited)
          .on('draw:deleted', this.onDeleted)
      } else {
        this.update(
          {
            type: 'Point',
            coordinates: this.coords[0],
          },
          true
        )
      }
    },
    reloadMarkers() {
      if (!this.map) {
        return
      }

      if (this.multiple) {
        this.editableLayers.eachLayer((layer) => {
          this.editableLayers.removeLayer(layer)
        })

        if (this.coords.length > 0) {
          this.coords.forEach((coords) => {
            const marker = L.marker(coords)
            this.editableLayers.addLayer(marker)
          })
        }
      } else {
        if (!this.coords || !this.coords[0]) {
          return
        }

        if (this.marker) {
          this.marker.remove()
        }

        const coords = this.coords[0]
        this.marker = L.marker(coords, {
          draggable: 'true',
        }).addTo(this.map)

        this.marker.on('dragend', () => {
          const position = this.marker.getLatLng()
          this.update({
            type: 'Point',
            coordinates: [position.lat, position.lng],
          })
        })
      }
    },
    onCreated(e) {
      this.editableLayers.addLayer(e.layer)
      this.updateCoords()
    },
    onEdited() {
      this.updateCoords()
    },
    onDeleted() {
      this.updateCoords()
    },
    updateCoords() {
      const coordinates = []
      this.editableLayers.eachLayer((layer) => {
        const latLng = layer.getLatLng()
        coordinates.push([latLng.lat, latLng.lng])
      })

      if (coordinates.length === 0) {
        this.update(null)
        return
      }

      this.update({ type: 'MultiPoint', coordinates })
    },
    onInput() {
      if (this.validCoords(this.coordsString)) {
        const coords = this.coordsString.split(',')
        this.update({
          type: 'Point',
          coordinates: [parseFloat(coords[0]), parseFloat(coords[1])],
        })
        this.map.panTo(coords)
      }
    },
    validCoords(val) {
      const pattern =
        /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?),\s*-?((1[0-7][0-9]|([1-9]?[0-9]))(\.\d+)?|180(\.0+)?)$/
      return pattern.test(val)
    },
  },
}
</script>

<style></style>
