<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-create disable-export :include="[]">
      <va-data-table :fields="fields" disable-select disable-actions>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['title', 'resource'],
  data() {
    return {
      filters: [],
      fields: [
        {
          source: 'content_order',
          type: 'number',
          label: 'Order',
          sortable: true,
        },
        {
          source: 'title',
          type: 'text',
          sortable: true,
        },
        {
          source: 'content',
          type: 'text',
          sortable: true,
        },
      ],
    }
  },
}
</script>
