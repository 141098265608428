<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-polygon-input
              source="geom"
              :zoom="11"
              append-icon="mdi-asterisk"></va-polygon-input>
            <va-select-input
              source="manure_storage_type"
              reference="manure_storage_types"></va-select-input>
            <va-text-input source="man_stor_loc"></va-text-input>
            <va-select-input
              source="manure_type"
              reference="manure_types"></va-select-input>
            <va-select-input
              source="containment_type"
              reference="containment_types"></va-select-input>
            <va-select-input
              source="storage_fully_contained"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="manure_storage_covered"
              reference="common_responses"></va-select-input>
            <v-row>
              <v-col cols="12" sm="6">
                <va-number-input
                  source="manure_storage_length_ft"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input
                  source="manure_storage_width_ft"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input
                  source="manure_storage_diameter_ft"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input
                  source="manure_storage_depth_ft"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input
                  source="manure_storage_volume_m3"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input
                  source="manure_storage_capacity"></va-number-input>
              </v-col>
            </v-row>
            <va-select-input
              source="storage_handling_adequate"
              reference="common_responses"></va-select-input>
            <va-field
              v-if="item"
              source="distance_nearest_well_m"
              type="number"
              :item="item"></va-field>
            <va-select-input
              source="distance_nearest_well_adequate"
              reference="common_responses"></va-select-input>
            <va-field
              v-if="item"
              source="distance_nearest_watercourse_ft"
              type="number"
              :item="item"></va-field>
            <va-select-input
              source="distance_nearest_watercourse_adequate"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="runoff_fresh_water_diverted"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="runoff_fresh_water_diversion_mechanism"
              reference="runoff_diversion_mechanisms"></va-select-input>
            <va-select-input
              source="runoff_waste_water_adequately_treated"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="runoff_treatment_type"
              reference="runoff_treatment_types"></va-select-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        geom: null,
        manure_storage_type: null,
        manure_type: null,
        containment_type: null,
        storage_fully_contained: null,
        manure_storage_covered: null,
        storage_handling_adequate: null,
        distance_nearest_well_adequate: null,
        distance_nearest_watercourse_adequate: null,
        runoff_fresh_water_diverted: null,
        runoff_fresh_water_diversion_mechanism: null,
        runoff_waste_water_adequately_treated: null,
        runoff_treatment_type: null,
      },
    }
  },
}
</script>
