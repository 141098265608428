<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
      :include="['event_type_entity']"
      disable-global-search>
      <va-data-table :fields="fields" disable-clone>
        <template v-slot:[`field.event_type`]="{ item }">
          <v-tooltip v-if="item.comment" top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on">
                <span>{{ item.event_type_entity.event_type }}</span>
              </v-chip>
            </template>
            <span>{{ item.comment }}</span>
          </v-tooltip>
          <v-chip v-else>
            <span>{{ item.event_type_entity.event_type }}</span>
          </v-chip>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'event_type',
          type: 'select',
          attributes: { reference: 'event_types' },
        },
      ],
      fields: [
        {
          source: 'event_type',
        },
        { source: 'event_date', type: 'date', sortable: true },
      ],
    }
  },
}
</script>
