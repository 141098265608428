<template>
  <va-show :item="item" :resource="resource">
    <va-field
      source="first_name"
      :labelKey="$t('contacts.first_name')"></va-field>
    <va-field
      source="first_name_common"
      :labelKey="$t('contacts.first_name_common')"></va-field>
    <va-field
      source="last_name"
      :labelKey="$t('contacts.last_name')"></va-field>
    <va-field
      source="contact_role_entity.contact_role"
      :labelKey="$t('contacts.contact_role_entity.contact_role')"
      type="select"
      chip
      reference="contact_roles"></va-field>
    <va-field source="email" :labelKey="$t('contacts.email')"></va-field>
    <va-field
      source="primary_contact"
      type="boolean"
      :labelKey="$t('contacts.primary_contact')"></va-field>
    <va-field source="comment" :labelKey="$t('contacts.comment')"></va-field>
    <va-phone-array-field source="contact_phones"> </va-phone-array-field>
  </va-show>
</template>

<script>
export default {
  props: ['title', 'item', 'resource'],
}
</script>
