<template>
  <va-edit-layout :actions="['list', 'show', 'delete']">
    <watercourses-form :id="id" :title="title" :item="item"></watercourses-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
}
</script>
