<template>
  <va-form
    :id="id"
    :item="formData"
    :resource="resource.name"
    v-model="model"
    disable-redirect
    @saved="onSaved">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">Current Stage Recommendations</div>
          </template>
          <v-card-text>
            <div
              class="primary display-1 font-weight-medium lighten-1 pa-3 rounded elevation-3 mt-2">
              <span class="white--text">{{ category }}</span>
            </div>
            <va-group-input
              hide-remove
              hide-add
              source="recommendations"
              label=" "
              :resource="resource.name"
              v-slot="props">
              <va-checkbox-input
                v-bind="props"
                source="check"
                :label="props.item.title">
              </va-checkbox-input>
              <v-row>
                <v-col cols="12" md="8">
                  <va-text-input
                    v-bind="props"
                    source="recommendation_text"
                    label="Recommendation Text"
                    multiline>
                  </va-text-input>
                </v-col>
                <v-col cols="12" md="4">
                  <va-select-input
                    v-bind="props"
                    source="recommendation_priority"
                    label="Priority"
                    :choices="recommendationPriorities"
                    item-text="recommendation_priority"
                    item-value="id"></va-select-input>
                  <va-number-input
                    v-bind="props"
                    source="recommendation_order"
                    label="Order">
                  </va-number-input>
                </v-col>
              </v-row>
              <div v-if="!isOpenStage">
                <div class="d-flex space-between">
                  <va-boolean-input
                    class="mr-4"
                    v-bind="props"
                    source="environmentally_significant"
                    label="Environmentally Significant">
                  </va-boolean-input>
                  <va-boolean-input
                    class="mr-4"
                    v-bind="props"
                    source="implemented"
                    label="Implemented">
                  </va-boolean-input>
                  <va-boolean-input
                    class="mr-4"
                    v-bind="props"
                    source="funds_received"
                    label="Funds Received">
                  </va-boolean-input>
                </div>
                <va-text-input
                  v-bind="props"
                  source="comment"
                  label="Comment"
                  multiline>
                </va-text-input>
              </div>
              <hr
                v-if="props.item.number < formData.recommendations.length"
                class="mt-2" />
            </va-group-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  props: ['id', 'title', 'resource'],
  watch: {
    '$route.params'(newVal, oldVal) {
      /**
       * Reload data when route params change.
       * Similar approach in vuetify-admin's ui/providor/List.vue in order to support loading nested routes.
       */
      if (!isEqual(newVal, oldVal)) {
        this.model = {
          stage_id: this.$route.params.stage_id,
        }
        this.getRecommendations()
      }
    },
  },
  computed: {
    category() {
      return (
        this.recommendations &&
        this.recommendations[0] &&
        `${this.recommendations[0].level1} - ${this.recommendations[0].level2}`
      )
    },
    isOpenStage() {
      const openStage = this.$store.state.global.openStage
      return openStage && openStage.id === parseInt(this.$route.params.stage_id)
    },
  },
  data() {
    return {
      formData: {
        recommendations: [],
      },
      model: {
        stage_id: this.$route.params.stage_id,
      },
      recommendationPriorities: [],
      recommendations: [],
    }
  },
  mounted() {
    this.getRecommendationPriorities()
    this.getRecommendations()
  },
  methods: {
    async getRecommendationPriorities() {
      try {
        const { data } = await this.$store.dispatch(
          'recommendation_priorities/getList'
        )
        this.recommendationPriorities = data
      } catch (error) {
        console.log(error)
      }
    },
    async getRecommendations() {
      try {
        const { data } = await this.$admin.http.get(
          'api/recommendations/list_by_category',
          {
            params: {
              stage_id: this.$route.params.stage_id,
              recommendation_id: this.$route.params.recommendation_id,
            },
          }
        )
        this.recommendations = data.data
        this.parseFormData()
      } catch (error) {
        console.log(error)
      }
    },
    parseFormData() {
      this.formData = {
        recommendations: this.recommendations.map((recommendation, index) => {
          const {
            id,
            app_b_label,
            recommendation_order,
            recc_full,
            farm_recommendation,
          } = recommendation
          let farmRecommendation = {
            number: index + 1,
            id: null,
            recommendation_id: id,
            title: app_b_label,
            check: false,
            recommendation_order,
            recommendation_text: recc_full,
            recommendation_priority: null,
          }

          if (!this.isOpenStage) {
            farmRecommendation = {
              ...farmRecommendation,
              environmentally_significant: false,
              implemented: false,
              funds_received: false,
              comment: null,
            }
          }

          if (farm_recommendation) {
            farmRecommendation = {
              ...farmRecommendation,
              id: farm_recommendation.id,
              check: true,
              recommendation_id: farm_recommendation.recommendation_id,
              recommendation_order: farm_recommendation.recommendation_order,
              recommendation_text: farm_recommendation.recommendation_text,
              recommendation_priority:
                farm_recommendation.recommendation_priority,
            }

            if (!this.isOpenStage) {
              farmRecommendation = {
                ...farmRecommendation,
                environmentally_significant:
                  farm_recommendation.environmentally_significant,
                implemented: farm_recommendation.implemented,
                funds_received: farm_recommendation.funds_received,
                comment: farm_recommendation.comment,
              }
            }
          }

          return farmRecommendation
        }),
      }
    },
    onSaved() {
      this.getRecommendations()
    },
  },
}
</script>
