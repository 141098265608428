<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-select-input
              source="event_type"
              :choices="eventTypeChoices"
              item-text="event_type"
              item-value="id"
              append-icon="mdi-asterisk"
              required></va-select-input>
            <va-datepicker-input
              source="event_date"
              :min="min"
              append-icon="mdi-asterisk"
              required></va-datepicker-input>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import isEqual from 'lodash/isEqual'

const EVENT_STAGE_INITIATION = 'Stage Initiation'
const EVENT_TYPE_INTERVIEW = 'Interview'
const EVENT_TYPE_APPENDIX_A_AND_B_SENT = 'Appendix A and B sent'

export default {
  props: ['id', 'title', 'item'],
  watch: {
    '$route.params'(newVal, oldVal) {
      /**
       * Reload data when route params change.
       * Similar approach in vuetify-admin's ui/providor/List.vue in order to support loading nested routes.
       */
      if (!isEqual(newVal, oldVal)) {
        this.model = {
          ...this.model,
          stage_id: this.$route.params.stage_id,
        }
        this.fetchStage()
      }
    },
  },
  computed: {
    eventTypeChoices() {
      if (!this.stage) {
        return []
      }

      // Only display Stage Initiation if it hasn't been created yet
      if (!this.eventStageInitiation) {
        return this.eventTypes.filter((eventType) => {
          return eventType.event_type === EVENT_STAGE_INITIATION
        })
        // Exclude Appendix A and B sent if no interview event
      } else if (!this.eventInterview) {
        return this.eventTypes.filter((eventType) => {
          return eventType.event_type !== EVENT_TYPE_APPENDIX_A_AND_B_SENT
        })
      } else {
        return this.eventTypes
      }
    },
    eventTypeStageInitiation() {
      return this.findByEventType(EVENT_STAGE_INITIATION)
    },
    eventTypeInterview() {
      return this.findByEventType(EVENT_TYPE_INTERVIEW)
    },
    eventStageInitiation() {
      return (
        this.stage &&
        this.stage.events.find((event) => {
          return event.event_type === this.eventTypeStageInitiation.id
        })
      )
    },
    eventInterview() {
      return (
        this.stage &&
        this.stage.events.find((event) => {
          return event.event_type === this.eventTypeInterview.id
        })
      )
    },
    min() {
      if (!this.stage) {
        return null
      }

      // Creating/Editing Event Stage Initiation
      if (
        this.model.event_type &&
        this.eventTypeStageInitiation.id === this.model.event_type
      ) {
        return null
      }

      if (this.stage.events.length > 0 && this.eventTypeStageInitiation) {
        return this.eventStageInitiation && this.eventStageInitiation.event_date
      }

      return null
    },
  },
  data() {
    return {
      model: {
        // Add custom fields that will be sent with the request
        stage_id: this.$route.params.stage_id,
        event_type: null,
        event_date: null,
      },
      stage: null,
      eventTypes: [],
    }
  },
  mounted() {
    this.fetchStage()
    this.getEventTypes()
  },
  methods: {
    async fetchStage() {
      try {
        const { data } = await this.$store.dispatch('stages/getOne', {
          id: this.$route.params.stage_id,
        })
        this.stage = data
      } catch (error) {
        console.log(error)
      }
    },
    async getEventTypes() {
      try {
        const { data } = await this.$store.dispatch('event_types/getList')
        this.eventTypes = data
      } catch (error) {
        console.log(error)
      }
    },
    findByEventType(type) {
      return (
        this.eventTypes &&
        this.eventTypes.length > 0 &&
        this.eventTypes.find((eventType) => {
          return eventType.event_type === type
        })
      )
    },
  },
}
</script>
