<template>
  <div id="leaflet-map"></div>
</template>

<script>
import L from 'leaflet'
import Field from 'vuetify-admin/src/mixins/field'
import genMap from '@/utils/gen-map'

export default {
  mixins: [Field],
  props: {
    zoom: {
      type: Number,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    points: [],
  },
  data() {
    return {
      map: null,
      featureGroup: null,
    }
  },
  computed: {
    coords() {
      if (this.points?.length > 0) {
        return this.points.map((point) => point.coordinates)
      }

      if (this.value) {
        return this.multiple ? this.value.coordinates : [this.value.coordinates]
      }

      return []
    },
    center() {
      return this.coords.length > 0
        ? this.coords[0]
        : this.$admin.options.map.coords
    },
  },
  watch: {
    points() {
      this.reloadMarkers()
    },
  },
  mounted() {
    this.initializeMap()
    this.reloadMarkers()
  },
  methods: {
    initializeMap() {
      this.map = L.map('leaflet-map').setView(
        this.center,
        this.zoom || this.$admin.options.map.zoom
      )

      genMap(this.map, L, this.$admin.options.map.maxZoom)
    },
    reloadMarkers() {
      if (this.featureGroup) {
        this.featureGroup.removeFrom(this.map)
      }
      const markers = []
      this.coords.forEach((coords) => {
        markers.push(L.marker(coords).bindPopup(coords.join(', ')))
      })
      this.featureGroup = new L.featureGroup(markers)
      this.featureGroup.addTo(this.map)
      const bounds = this.featureGroup.getBounds()
      if (Object.keys(bounds).length > 0) {
        this.map.fitBounds(bounds)
      }
    },
  },
}
</script>

<style></style>
