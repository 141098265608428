import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#008ec1',
        success: '#719b49',
      },
      dark: {
        primary: '#008ec1',
        success: '#719b49',
      },
    },
  },
})
