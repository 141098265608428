<template>
  <base-material-card :icon="resource.icon" :title="title">
    <!-- Include relationship entities -->
    <va-list
      :filters="filters"
      :include="[
        'main_crop_entity',
        'land_ownership_entity',
        'cover_crop_used_entity',
        'tillage_entity',
      ]"
      :sort-by="['land_ownership_entity.ownership', 'crop_area_ac']"
      :sort-desc="[true, true]">
      <va-data-table :fields="fields"> </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [],
      fields: [
        { source: 'field_id', sortable: true },
        {
          source: 'main_crop_entity.class',
          type: 'select',
          attributes: { chip: true },
        },
        { source: 'crop_area_ac', type: 'number', sortable: true },
        {
          source: 'land_ownership_entity.ownership',
          type: 'select',
          sortable: true,
          attributes: { chip: true },
        },
        {
          source: 'cover_crop_used_entity.common_response',
          type: 'select',
          attributes: { chip: true },
        },
        {
          source: 'tillage_entity.tillage',
          type: 'select',
          attributes: { chip: true },
        },
      ],
    }
  },
}
</script>
