<template>
  <va-form
    :id="id"
    :item="formData"
    :resource="resource.name"
    v-model="model"
    disable-redirect
    @saved="onSaved">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ category }}
            </div>
          </template>
          <v-card-text>
            <va-group-input
              hide-remove
              hide-add
              source="questions"
              label=" "
              :resource="resource.name"
              v-slot="props">
              <div
                v-if="props.item.level2"
                class="primary display-1 font-weight-medium lighten-1 pa-3 rounded elevation-3 mt-4">
                <span class="white--text">{{ props.item.level2 }}</span>
              </div>
              <div class="d-flex justify-space-between align-center">
                <div class="display-1 font-weight-medium">
                  Question {{ props.item.number }}: {{ props.item.question }}
                </div>
                <va-boolean-input
                  label="Info"
                  v-bind="props"
                  source="answer.info_needed">
                </va-boolean-input>
              </div>
              <va-text-input
                v-if="props.item.question_data_entry === 'Text'"
                label="Answer"
                v-bind="props"
                source="answer.answer">
              </va-text-input>
              <va-radio-group-input
                v-else-if="props.item.question_data_entry === 'Select'"
                row
                label=" "
                v-bind="props"
                source="answer.answer"
                :choices="commonResponses"
                item-text="common_response"
                item-value="common_response"></va-radio-group-input>
              <va-text-input
                label="Comment"
                v-bind="props"
                source="answer.comment">
              </va-text-input>
              <div v-if="props.item.coordinator_info">
                <div class="display-1 mb-1">Coordinator Info</div>
                <div class="body-2">
                  <div v-if="props.item.coordinator_info.content_name">
                    {{ props.item.coordinator_info.content_name }}
                  </div>
                  <div v-if="props.item.coordinator_info.content_url">
                    {{ props.item.coordinator_info.content_url }}
                  </div>
                </div>
                <!-- <a
                  :href="props.item.coordinator_info.content_url"
                  target="_blank"
                  tabindex="-1"
                  >{{ props.item.coordinator_info.content_name }}</a
                > -->
              </div>
              <hr
                v-if="props.item.number < formData.questions.length"
                class="mt-4" />
            </va-group-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import isEqual from 'lodash/isEqual'

import { eventBus } from '@/main'

export default {
  props: ['id', 'title', 'resource'],
  watch: {
    '$route.params'(newVal, oldVal) {
      /**
       * Reload data when route params change.
       * Similar approach in vuetify-admin's ui/providor/List.vue in order to support loading nested routes.
       */
      if (!isEqual(newVal, oldVal)) {
        this.model = {
          stage_id: this.$route.params.stage_id,
        }
        this.getQuestions()
      }
    },
  },
  computed: {
    category() {
      const question = this.formData.questions[0]
      return question && question.level1
    },
  },
  data() {
    return {
      formData: {
        questions: [],
      },
      model: {
        stage_id: this.$route.params.stage_id,
      },
      commonResponses: [],
    }
  },
  mounted() {
    this.getCommonResponses()
    this.getQuestions()
  },
  methods: {
    async getQuestions() {
      try {
        const { data } = await this.$admin.http.get('api/questionnaires', {
          params: {
            stage_id: this.$route.params.stage_id,
            question_id: this.$route.params.questionnaire_id,
          },
        })
        this.formData = {
          ...this.formData,
          questions: this.parseQuestions(data.data),
        }

        this.scrollToTop()
      } catch (error) {
        console.log(error)
      }
    },
    async getCommonResponses() {
      try {
        const { data } = await this.$store.dispatch('common_responses/getList')
        this.commonResponses = data
      } catch (error) {
        console.log(error)
      }
    },
    parseQuestions(questions) {
      const subcategories = new Set()
      const parsedQuestions = questions.reduce((aggr, item, index) => {
        const { id, level1, level2, question, answer, coordinator_info } = item
        const question_data_entry = item.question_data_entry.question_data_entry
        const parsedQuestion = {
          id,
          number: index + 1,
          question,
          question_data_entry,
          level1,
          answer: answer
            ? {
                id: answer.id,
                answer: answer.answer,
                comment: answer.comment,
                info_needed: answer.info_needed,
              }
            : {
                answer: null,
                comment: null,
                info_needed: false,
              },
          coordinator_info: coordinator_info
            ? {
                content_name: coordinator_info.content_name,
                content_url: coordinator_info.content_url,
              }
            : null,
        }

        if (!subcategories.has(level2)) {
          parsedQuestion.level2 = level2
          subcategories.add(level2)
        }

        aggr.push(parsedQuestion)

        return aggr
      }, [])

      return parsedQuestions
    },
    onSaved() {
      this.getQuestions()
      eventBus.$emit('questionnaire-changed')
    },
    scrollToTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  },
}
</script>
