import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c(VTabs,{attrs:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.id,attrs:{"href":`#tab-${tab.id}`}},[(tab.icon)?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(tab.icon))]):_vm._e(),_vm._v(" "+_vm._s(tab.label || _vm.$t(`tabs.${tab.id}`))+" ")],1)}),1)]},proxy:true}])},[_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTabItem,{key:tab.id,attrs:{"value":`tab-${tab.id}`}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_vm._t(tab.id)],2)],1)],1)}),1),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }