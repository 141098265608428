<template>
  <div>
    <va-polygon-field
      :polygons="polygons"
      :zoom="8"
      height="500px"></va-polygon-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        :include="[
          'manure_storage_type_entity',
          'manure_type_entity',
          'containment_type_entity',
          'storage_handling_adequate_entity',
        ]"
        @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'manure_storage_type',
          type: 'select',
          attributes: { reference: 'manure_storage_types' },
        },
      ],
      fields: [
        {
          source: 'manure_storage_type_entity.manure_storage_type',
          type: 'select',
          attributes: { chip: true },
        },
        {
          source: 'manure_type_entity.manure_type',
          type: 'select',
          attributes: { chip: true },
        },
        { source: 'man_stor_loc', type: 'text', sortable: true },
        {
          source: 'containment_type_entity.containment_type',
          type: 'select',
          attributes: { chip: true },
        },
        {
          source: 'storage_handling_adequate_entity.common_response',
          type: 'select',
          attributes: { chip: true },
        },
      ],
      polygons: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.polygons = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
