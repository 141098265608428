import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":"","dark":_vm.dark,"color":_vm.color,"mini-variant":_vm.miniVariant,"value":_vm.value},on:{"input":(v) => _vm.$emit('input', v)},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_vm._t("img",null,null,props)]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_vm._l((_vm.menu.filter((l) => l)),function(item,index){return [(item.heading && !_vm.miniVariant)?_c(VSubheader,{key:index},[_vm._v(" "+_vm._s(item.heading)+" ")]):(item.divider)?_c(VDivider,{key:index}):(item.children && item.children.length)?_c(VListGroup,{key:index,attrs:{"value":_vm.routeMatch(item),"prepend-icon":item.icon,"append-icon":"mdi-chevron-up"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.text)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((item.children),function(child,childIndex){return [(child.children && child.children.length)?_c(VListGroup,{key:childIndex,attrs:{"value":_vm.routeMatch(child),"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(child.text))])],1)]},proxy:true}],null,true)},_vm._l((child.children),function(subChild,i){return _c(VListItem,{key:i,attrs:{"to":subChild.link,"link":""}},[_c(VListItemTitle,[_vm._v(_vm._s(subChild.text))])],1)}),1):_c(VListItem,{key:childIndex,attrs:{"link":"","to":child.link,"exact-path":!!child.exactPath}},[(child.icon)?_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(child.icon))])],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(child.text)+" ")])],1)],1)]})],2):(item.text)?_c(VListItem,{key:index,attrs:{"link":"","to":item.link}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }