<template>
  <va-edit-layout :actions="['list', 'show', 'delete']">
    <point-source-runoffs-form :id="id" :title="title" :item="item">
    </point-source-runoffs-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
}
</script>
