export default {
  namespaced: true,
  state: {
    openStage: null,
  },
  mutations: {
    setOpenStage(state, payload) {
      state.openStage = payload
    },
  },
  actions: {},
}
