<template>
  <div>
    <va-polygon-field
      :polygons="polygons"
      :zoom="8"
      height="500px"></va-polygon-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        :include="['feed_storage_type_entity']"
        @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'feed_storage_type',
          type: 'select',
          attributes: { reference: 'feed_storage_types' },
        },
      ],
      fields: [
        {
          source: 'feed_storage_type_entity.feed_storage_type',
          type: 'select',
          attributes: { reference: 'feed_storage_types', chip: true },
        },
        {
          source: 'comment',
        },
      ],
      polygons: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.polygons = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
