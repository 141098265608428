<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-location-input source="geom"></va-location-input>
            <va-number-input source="farm_number"></va-number-input>
            <va-text-input source="bn"></va-text-input>
            <va-text-input
              source="farm_name"
              append-icon="mdi-asterisk"
              required>
            </va-text-input>
            <va-text-input source="alt_name"></va-text-input>
            <va-boolean-input source="active"></va-boolean-input>
            <va-text-input
              source="address1"
              append-icon="mdi-asterisk"
              required></va-text-input>
            <v-row>
              <v-col cols="12" sm="6">
                <va-text-input source="community_civic"></va-text-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-select-input
                  source="county_id"
                  reference="counties"
                  model="county_id"
                  append-icon="mdi-asterisk"
                  required></va-select-input>
              </v-col>
            </v-row>
            <va-text-input source="address2"></va-text-input>
            <v-row>
              <v-col cols="12" sm="6">
                <va-text-input source="community_mailing"></va-text-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-text-input source="postal_code"></va-text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <va-text-input source="phone"></va-text-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-text-input source="fax"></va-text-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-text-input source="email"></va-text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <va-boolean-input source="registered"></va-boolean-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-boolean-input source="organic"></va-boolean-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="item" cols="12" sm="6">
                <va-field source="watershed" :item="item"></va-field>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input
                  source="designated_watershed_id"></va-number-input>
              </v-col>
            </v-row>
            <va-select-input
              source="coordinator_id"
              reference="coordinators"
              model="coordinator_id"
              append-icon="mdi-asterisk"
              required></va-select-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        geom: null,
        county_id: null,
        coordinator_id: null,
      },
    }
  },
}
</script>
