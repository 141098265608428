<template>
  <va-create-layout>
    <properties-form :title="title" :item="item"></properties-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
