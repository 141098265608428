<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-polygon-field source="geom"></va-polygon-field>
              <va-field
                source="feed_storage_type_entity.feed_storage_type"
                type="select"
                reference="feed_storage_types"
                chip></va-field>
              <va-field source="distance_to_well_m" type="number"></va-field>
              <va-field
                source="distance_to_well_adequate_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="distance_to_watercourse_m"
                type="number"></va-field>
              <va-field
                source="distance_to_watercourse_adequate_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="runoff_fresh_water_diverted_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="runoff_waste_water_adequately_treated_entity.common_response"
                type="select"
                reference="common_responses"
                chip></va-field>
              <va-field
                source="runoff_treatment_type_entity.runoff_treatment_type"
                type="select"
                reference="runoff_treatment_types"
                chip></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
