import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import admin from './plugins/admin'
import './plugins/i18n'
import './plugins/base'
import './plugins/chartist'
import './sass/overrides.sass'
import './sass/global.sass'
import LocationInput from './components/inputs/LocationInput'
import GroupInput from './components/inputs/GroupInput'
import LocationField from './components/fields/LocationField'
import PhoneArrayField from './components/fields/PhoneArrayField'
import StagesField from './components/fields/StagesField'
import PolygonField from './components/fields/PolygonField'
import PolygonInput from './components/inputs/PolygonInput'
import LineInput from './components/inputs/LineInput'
import LineField from './components/fields/LineField'
import CheckboxInput from './components/inputs/CheckboxInput'
import DatepickerInput from './components/inputs/DatepickerInput'
import DaterangepickerInput from './components/inputs/DaterangepickerInput'

Vue.component('VaLocationInput', LocationInput)
Vue.component('VaGroupInput', GroupInput)
Vue.component('VaLocationField', LocationField)
Vue.component('VaPhoneArrayField', PhoneArrayField)
Vue.component('VaStagesField', StagesField)
Vue.component('VaPolygonField', PolygonField)
Vue.component('VaPolygonInput', PolygonInput)
Vue.component('VaLineInput', LineInput)
Vue.component('VaLineField', LineField)
Vue.component('VaCheckboxInput', CheckboxInput)
Vue.component('VaDatepickerInput', DatepickerInput)
Vue.component('VaDaterangepickerInput', DaterangepickerInput)

Vue.config.productionTip = false

export const eventBus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  i18n,
  admin,

  render: function (h) {
    return h(App)
  },
}).$mount('#app')
