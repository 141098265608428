<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-line-input source="geom" multiple></va-line-input>
            <va-select-input
              source="watercourse_type"
              reference="watercourse_types"></va-select-input>
            <va-text-input source="watercourse_name"></va-text-input>
            <va-text-input source="watercourse_location"></va-text-input>
            <va-boolean-input source="watercourse_crossing"></va-boolean-input>
            <va-number-input
              source="riparian_zone_adequacy"
              :min="0"
              :max="100"></va-number-input>
            <va-number-input
              source="buffer_strip_adequacy"
              :min="0"
              :max="100"></va-number-input>
            <va-text-input source="riparian_comment"></va-text-input>
            <va-select-input
              source="livestock_access"
              reference="livestock_access_watercourses"></va-select-input>
            <va-text-input source="livestock_access_comment"></va-text-input>
            <va-text-input source="crossings_comment"></va-text-input>
            <va-select-input
              source="irrigation"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="livestock"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="frost"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="over_23kl_per_day"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="using_water_meter"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="withdrawal_approval"
              reference="common_responses"></va-select-input>
            <va-select-input
              source="last_bacteria_test"
              reference="time_ranges"></va-select-input>
            <va-number-input
              source="bacteria_testing_frequency"
              :min="0"
              :max="99"></va-number-input>
            <va-text-input source="bacteria_status_comment"></va-text-input>
            <va-text-input source="comment"></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        stage_id: this.$route.params.stage_id,
        geom: null,
        watercourse_type: null,
        livestock_access: null,
        irrigation: null,
        livestock: null,
        frost: null,
        over_23kl_per_day: null,
        using_water_meter: null,
        withdrawal_approval: null,
        last_bacteria_test: null,
        bacteria_testing_frequency: null,
      },
    }
  },
}
</script>
