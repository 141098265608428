<template>
  <div>
    <va-location-field
      :points="points"
      :zoom="11"
      multiple
      height="500px"></va-location-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        :include="['chemical_storage_type', 'dedicated_building']"
        @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone>
          <template v-slot:[`field.chemical_storage_type`]="{ value }">
            <va-select-field
              reference="chemical_storage_types"
              chip
              :item="value.chemical_storage_type">
            </va-select-field>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [
        {
          source: 'chemical_storage_type',
          type: 'select',
          attributes: { reference: 'chemical_storage_types' },
        },
      ],
      fields: [
        {
          source: 'chemical_storage_type.chemical_storage_type',
          type: 'select',
          labelKey: 'chemical_storage_type',
          attributes: { chip: true },
        },
        {
          source: 'dedicated_building.common_response',
          type: 'select',
          labelKey: 'dedicated_building',
          attributes: { chip: true },
        },
        { source: 'comment', sortable: true },
      ],
      points: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.points = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
