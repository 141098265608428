<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <contacts-show
        v-if="show"
        :resource="resource.name"
        :item="item"></contacts-show>
      <contacts-form
        v-else
        :resource="resource.name"
        :id="id"
        :item="item"
        :farm-id="farmId"
        @saved="onSaved"></contacts-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="resource.pluralName">
      <va-list
        ref="list"
        :resource="resource.name"
        disable-query-string
        disable-create-redirect
        @action="onAction"
        :filter="{
          farm: farmId,
        }"
        :filters="filters"
        :include="['contact_role_entity', 'contact_phones']">
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          disable-clone
          @item-action="onAction">
          <template v-slot:[`field.first_name`]="{ value, item }">
            <v-tooltip v-if="item.comment" top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ value }}</span>
              </template>
              <span>{{ item.comment }}</span>
            </v-tooltip>
            <span v-else>
              {{ value }}
            </span>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title', 'farmId'],
  data() {
    return {
      filters: [],
      fields: [
        { source: 'first_name', sortable: true },
        { source: 'first_name_common', sortable: true },
        { source: 'last_name', sortable: true },
        { source: 'contact_role_entity.contact_role' },
        { source: 'primary_contact', type: 'boolean', sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    }
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title
      this.id = id
      this.show = action === 'show'
      this.item = item
    },
    onSaved() {
      this.asideTitle = null
      this.$refs.list.fetchData()
    },
  },
}
</script>
