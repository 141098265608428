<template>
  <div id="leaflet-map"></div>
</template>

<script>
import L from 'leaflet'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import Input from 'vuetify-admin/src/mixins/input'
import isEqual from 'lodash/isEqual'
import genMap from '@/utils/gen-map'

export default {
  mixins: [Input],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    polygonCoords() {
      if (!this.input) {
        return []
      }

      if (this.input.type.toUpperCase() === 'MULTIPOLYGON') {
        return this.input.coordinates
      } else {
        return [this.input.coordinates]
      }
    },
    center() {
      return this.polygonCoords.length > 0
        ? this.polygonCoords[0][0][0]
        : this.$admin.options.map.coords
    },
  },
  watch: {
    polygonCoords() {
      this.reloadPolygon()
    },
  },
  data() {
    return {
      map: null,
      editableLayers: null,
    }
  },
  mounted() {
    this.initializeMap()
    this.reloadPolygon()
  },
  methods: {
    initializeMap() {
      this.map = L.map('leaflet-map').setView(
        this.center,
        this.zoom || this.$admin.options.map.zoom
      )

      genMap(this.map, L, this.$admin.options.map.maxZoom)

      this.editableLayers = new L.FeatureGroup()
      this.map.addLayer(this.editableLayers)

      const drawPluginOptions = {
        position: 'topright',
        draw: {
          polyline: false,
          polygon: true,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false,
        },
        edit: {
          featureGroup: this.editableLayers,
          remove: true,
        },
      }

      const drawControl = new L.Control.Draw(drawPluginOptions)
      this.map.addControl(drawControl)

      this.map
        .on('draw:drawstart', this.onDrawStart)
        .on('draw:created', this.onCreated)
        .on('draw:edited', this.onEdited)
        .on('draw:deleted', this.onDeleted)
    },
    reloadPolygon() {
      this.editableLayers.eachLayer((layer) => {
        this.editableLayers.removeLayer(layer)
      })

      if (this.polygonCoords.length > 0) {
        this.polygonCoords.forEach((coords) => {
          const polygon = L.polygon(coords)
          this.editableLayers.addLayer(polygon)
        })
      }
    },
    onDrawStart() {
      if (this.multiple) {
        return
      }

      /**
       * Remove all layers before drawing if only one polygon is allowed
       */
      this.editableLayers.eachLayer((layer) => {
        this.editableLayers.removeLayer(layer)
      })
    },
    onCreated(e) {
      this.editableLayers.addLayer(e.layer)
      this.updatePolygonCoords()
    },
    onEdited() {
      this.updatePolygonCoords()
    },
    onDeleted() {
      this.updatePolygonCoords()
    },
    updatePolygonCoords() {
      const latLngGroup = []
      this.editableLayers.eachLayer((layer) => {
        latLngGroup.push(layer.getLatLngs())
      })

      if (latLngGroup.length === 0) {
        this.update(null)
        return
      }

      const coordinates = latLngGroup.map((group) => this.convertLatLngs(group))
      /**
       * Update the input value which will trigger reloading the polygon
       */
      const input = this.multiple
        ? { type: 'MultiPolygon', coordinates }
        : { type: 'Polygon', coordinates: coordinates[0] }
      this.update(input)
    },
    convertLatLngs(latLngGroup) {
      return latLngGroup.map((latLngs) => {
        latLngs = latLngs.map((latLng) => {
          return [latLng.lat, latLng.lng]
        })

        if (!isEqual(latLngs[0], latLngs[latLngs.length - 1])) {
          latLngs.push(latLngs[0])
        }

        return latLngs
      })
    },
  },
}
</script>

<style></style>
