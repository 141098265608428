<template>
  <div>
    <va-location-field
      :points="points"
      :zoom="8"
      height="500px"></va-location-field>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        :filters="filters"
        :include="[]"
        @update:listState="onListStateUpdate">
        <va-data-table :fields="fields" disable-clone> </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ['resource', 'title'],
  data() {
    return {
      filters: [],
      fields: [{ source: 'comment', sortable: true }],
      points: [],
    }
  },
  methods: {
    onListStateUpdate(listState) {
      if (listState && listState.items) {
        this.points = listState.items.map((item) => item.geom)
      }
    },
  },
}
</script>
