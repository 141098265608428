<template>
  <va-show-layout :actions="['list', 'edit', 'delete']">
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8" md="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-line-field source="geom"></va-line-field>
              <va-field
                source="watercourse_type_entity.watercourse_type"
                type="select"
                chip
                :label="$t('resources.watercourses.fields.watercourse_type')"
                reference="watercourse_types"></va-field>
              <va-field source="watercourse_name"></va-field>
              <va-field source="watercourse_location"></va-field>
              <va-field source="watercourse_crossing" type="boolean"></va-field>
              <va-field
                source="riparian_zone_adequacy"
                type="number"></va-field>
              <va-field source="buffer_strip_adequacy" type="number"></va-field>
              <va-field source="riparian_comment"></va-field>
              <va-field
                source="livestock_access_entity.livestock_access_watercourse"
                type="select"
                :label="$t('resources.watercourses.fields.livestock_access')"
                chip
                reference="livestock_access_watercourses"></va-field>
              <va-field source="livestock_access_comment"></va-field>
              <va-field source="crossings_comment"></va-field>
              <va-field
                source="irrigation_entity.common_response"
                type="select"
                :label="$t('resources.watercourses.fields.irrigation')"
                chip
                reference="common_responses"></va-field>
              <va-field
                source="livestock.common_response"
                type="select"
                :label="$t('resources.watercourses.fields.livestock')"
                chip
                reference="common_responses"></va-field>
              <va-field
                source="frost.common_response"
                :label="$t('resources.watercourses.fields.frost')"
                type="select"
                chip
                reference="common_responses"></va-field>
              <va-field
                source="over_23kl_per_day.common_response"
                type="select"
                :label="$t('resources.watercourses.fields.over_23kl_per_day')"
                chip
                reference="common_responses"></va-field>
              <va-field
                source="using_water_meter.common_response"
                type="select"
                :label="$t('resources.watercourses.fields.using_water_meter')"
                chip
                reference="common_responses"></va-field>
              <va-field
                source="withdrawal_approval.common_response"
                type="select"
                :label="$t('resources.watercourses.fields.withdrawal_approval')"
                chip
                reference="common_responses"></va-field>
              <va-field
                source="last_bacteria_test.time_range"
                type="select"
                :label="$t('resources.watercourses.fields.last_bacteria_test')"
                chip
                reference="time_ranges"></va-field>
              <va-field
                source="bacteria_testing_frequency.bacteria_testing_frequency"
                type="number"
                :label="
                  $t('resources.watercourses.fields.bacteria_testing_frequency')
                "></va-field>
              <va-field source="bacteria_status_comment"></va-field>
              <va-field source="comment"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ['title', 'item'],
}
</script>
