<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col lg="8" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-polygon-input
              source="geom"
              :zoom="11"
              :required="false"></va-polygon-input>
            <va-text-input source="efp_field_name"></va-text-input>
            <va-text-input source="efp_sample_name"></va-text-input>
            <va-text-input source="soil_sample_date"></va-text-input>
            <v-row>
              <v-col cols="12" sm="6">
                <va-number-input source="soil_sample_depth"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="reported_area_ac"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-text-input source="nsda_client_id"></va-text-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="area_sqm"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-text-input source="nsda_sample_num"></va-text-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="nsda_order_id"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="nitrogen"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-text-input source="nsda_sample_id"></va-text-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_buffer_ph"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_ph"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_p205"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_om"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_ca"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_k2o"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_na"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_mg"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_al"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_s"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_cu"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_b"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_mn"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_fe"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_cec"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_zn"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_base_sat_ca"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_base_sat_k"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_base_sat_na"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_base_sat_mg"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_lime_req"></va-number-input>
              </v-col>
              <v-col cols="12" sm="6">
                <va-number-input source="results_base_sat_h"></va-number-input>
              </v-col>
            </v-row>
            <va-text-input source="comment" multiline></va-text-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ['id', 'title', 'item'],
  data() {
    return {
      model: {
        // Add custom fields that will be send with the request
        stage_id: this.$route.params.stage_id,
        geom: null,
      },
    }
  },
}
</script>
