<template>
  <va-action-button
    :item="item"
    @click="downloadReport"
    :hide-label="icon"
    label="Download Report"
    icon="mdi-cloud-download-outline"
    color="success"
    text></va-action-button>
</template>

<script>
export default {
  name: 'DownloadButton',
  props: {
    item: Object,
    icon: Boolean,
  },
  methods: {
    async downloadReport() {
      try {
        const { data } = await this.$admin.http.get('api/reports/download', {
          params: {
            stage_id: this.$route.params.stage_id,
            report_id: this.item.id,
          },
          responseType: 'blob',
        })

        /**
         * Magic download
         */
        const fileName = this.item.name
        const blob = new Blob([data])

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, `${fileName}.docx`)
          return
        }

        const fakeLink = document.createElement('a')
        fakeLink.style.display = 'none'
        document.body.appendChild(fakeLink)

        fakeLink.setAttribute('href', URL.createObjectURL(blob))
        fakeLink.setAttribute('download', `${fileName}.docx`)
        fakeLink.click()
      } catch (e) {
        this.$admin.toast.error(e.message)
      }
    },
  },
}
</script>
