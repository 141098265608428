<template>
  <div id="leaflet-map" :style="style"></div>
</template>

<script>
import genMap from '@/utils/gen-map'
import L from 'leaflet'
import Field from 'vuetify-admin/src/mixins/field'

export default {
  mixins: [Field],
  props: {
    zoom: {
      type: Number,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    polylines: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      map: null,
      featureGroup: null,
    }
  },
  computed: {
    style() {
      return this.height ? { height: this.height } : null
    },
    polylineCoords() {
      if (this.polylines.length > 0) {
        return this.polylines.map((polyline) => polyline.coordinates)
      }

      if (!this.value) {
        return [[this.$admin.options.map.coords]]
      }

      if (this.value.type.toUpperCase() === 'MultiLineString') {
        return this.value.coordinates.map((coordinates) => [coordinates])
      }

      if (Array.isArray(this.value)) {
        return this.value.map((polyline) => polyline.coordinates)
      }
      return [this.value.coordinates]
    },
    center() {
      return Array.isArray(this.polylineCoords[0][0][0])
        ? this.polylineCoords[0][0][0]
        : this.polylineCoords[0][0]
    },
  },
  watch: {
    polylines() {
      this.reloadPolylines()
    },
  },
  mounted() {
    this.initializeMap()
    this.reloadPolylines()
  },
  methods: {
    initializeMap() {
      this.map = L.map('leaflet-map').setView(
        this.center,
        this.zoom || this.$admin.options.map.zoom
      )

      genMap(this.map, L, this.$admin.options.map.maxZoom)
    },
    reloadPolylines() {
      if (this.featureGroup) {
        this.featureGroup.removeFrom(this.map)
      }

      const polylines = this.polylineCoords.map((coords) => L.polyline(coords))

      this.featureGroup = new L.featureGroup(polylines)
      this.featureGroup.addTo(this.map)
      this.map.fitBounds(this.featureGroup.getBounds())
    },
  },
}
</script>
